import { Dialog, Toast } from "vant";
import { getStore } from "../store";
import DIC from "../dic";

export class Tool {
  constructor() {
    this.DIC = {};
    this.init();
  }

  // 初始化函数
  init() {
    this.SetDic("ThemeList");
    this.setDataTheme(getStore({ name: "themeName" }) ?? "XiaoMing");
  }

  // 设置字典
  SetDic(name) {
    Object.assign(this.DIC, { [name]: DIC[name] });
  }

  // 设置灰度模式
  toggleGrayMode(status) {
    if (status) {
      document.body.className = document.body.className + " grayMode";
    } else {
      document.body.className = document.body.className.replace(
        " grayMode",
        ""
      );
    }
  }

  // 设置主题
  setDataTheme(name) {
    document.body.setAttribute("data-theme", name);
  }

  // 后退
  backBefor() {
    window.history.back(-1);
  }

  // 手机号隐去中间四个字符
  hideMobile(list) {
    list.forEach(item => {
      if (typeof item.phone === "string") {
        let hideNum =
          item.phone.toString().substr(0, 3) +
          "****" +
          item.phone.substr(7, 11);
        item.phone = hideNum;
      }
    });
    return list;
  }

  getQueryString(url, paraName) {
    const arrObj = url.split("?");
    if (arrObj.length > 1) {
      const arrPara = arrObj[1].split("&");
      let arr;
      for (let i = 0; i < arrPara.length; i++) {
        arr = arrPara[i].split("=");
        // eslint-disable-next-line
        if (arr != null && arr[0] == paraName) {
          return arr[1];
        }
      }
      return "";
    } else {
      return "";
    }
  }

  /**
   * @param {String} url
   * @description 从URL中解析参数
   */
  getParams = url => {
    const keyValueArr = url.split("?")[1].split("&");
    // eslint-disable-next-line
    let paramObj = {};
    keyValueArr.forEach(item => {
      const keyValue = item.split("=");
      paramObj[keyValue[0]] = keyValue[1];
    });
    return paramObj;
  };

  /**
   * @param {string} url
   * @returns {Object}
   */
  getQueryObject(url) {
    url = url == null ? window.location.href : url;
    const search = url.substring(url.lastIndexOf("?") + 1);
    const obj = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
      const name = decodeURIComponent($1);
      let val = decodeURIComponent($2);
      val = String(val);
      obj[name] = val;
      return rs;
    });
    return obj;
  }

  /**
   * @description 格式化金额
   * @param number：要格式化的数字
   * @param decimals：保留几位小数 默认0位
   * @param decPoint：小数点符号 默认.
   * @param thousandsSep：千分位符号 默认为,
   */
  formatMoney = (number, decimals = 0, decPoint = ".", thousandsSep = ",") => {
    number = (number + "").replace(/[^0-9+-Ee.]/g, "");
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
    const dec = typeof decPoint === "undefined" ? "." : decPoint;
    let s = "";
    const toFixedFix = function(n, prec) {
      const k = Math.pow(10, prec);
      return "" + Math.ceil(n * k) / k;
    };
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    const re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  };

  /**
   * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
   * 如果没有传入key这个参数，则判断obj对象是否有键值对
   */
  hasKey = (obj, key) => {
    if (key) return key in obj;
    else {
      const keysArr = Object.keys(obj);
      return keysArr.length;
    }
  };

  /**
   * @param {*} obj1 对象
   * @param {*} obj2 对象
   * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
   */
  objEqual = (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1);
    const keysArr2 = Object.keys(obj2);
    if (keysArr1.length !== keysArr2.length) return false;
    else if (keysArr1.length === 0 && keysArr2.length === 0) return true;
    /* eslint-disable-next-line */ else
      return !keysArr1.some(key => obj1[key] != obj2[key]);
  };

  /**
   * Check if an element has a class
   * @param {HTMLElement} elm
   * @param {string} cls
   * @returns {boolean}
   */
  hasClass(ele, cls) {
    return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
  }

  /**
   * Add class to element
   * @param {HTMLElement} elm
   * @param {string} cls
   */
  addClass(ele, cls) {
    if (!this.hasClass(ele, cls)) ele.className += " " + cls;
  }

  /**
   * Remove class from element
   * @param {HTMLElement} elm
   * @param {string} cls
   */
  removeClass(ele, cls) {
    if (this.hasClass(ele, cls)) {
      const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
      ele.className = ele.className.replace(reg, " ");
    }
  }

  // 剪切板复制
  clipboardCopy(text) {
    // Use the Async Clipboard API when available
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text);
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    var span = document.createElement("span");
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = "pre";

    // An <iframe> isolates the <span> from the page's styles
    var iframe = document.createElement("iframe");
    iframe.sandbox = "allow-same-origin";

    // Add the <iframe> to the page
    document.body.appendChild(iframe);
    var win = iframe.contentWindow;

    // Add the <span> to the <iframe>
    win.document.body.appendChild(span);

    // Get a Selection object representing the range of text selected by the user
    var selection = win.getSelection();

    // Fallback for Firefox which fails to get a selection from an <iframe>
    if (!selection) {
      win = window;
      selection = win.getSelection();
      document.body.appendChild(span);
    }

    var range = win.document.createRange();
    selection.removeAllRanges();
    range.selectNode(span);
    selection.addRange(range);

    var success = false;
    try {
      success = win.document.execCommand("copy");
    } catch (err) {}

    selection.removeAllRanges();
    win.document.body.removeChild(span);
    document.body.removeChild(iframe);

    // The Async Clipboard API returns a promise that may reject with `undefined` so we
    // match that here for consistency.
    return success ? Promise.resolve() : Promise.reject(); // eslint-disable-line prefer-promise-reject-errors
  }
  params(obj) {
    let result = '';
    let item;
    for (item in obj) {
      if (obj[item] && String(obj[item])) {
        result += `&${item}=${encodeURIComponent(obj[item])}`;
      }
    }
    if (result) result = '?' + result.slice(1);
    return result;
  }
  /**
   * 获取uuid
   */
  getUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
        16
      );
    });
  }

  // 随机生成UUID
  uuid(length = 32) {
    const num =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += num.charAt(Math.floor(Math.random() * num.length));
    }
    return str;
  }

  /**
   * 转换为 千分位形式价格
   * fixed: 保留小数点位数
   */
  parseToMoney(num, fixed) {
    if (fixed) num = parseFloat(num.toFixed(fixed));
    let [integer, decimal] = num.toString().split(".");
    integer = integer.replace(/\d(?=(\d{3})+$)/g, "$&,");
    return decimal ? `${integer}.${decimal}` : integer;
  }

  // 判断是否为微信
  isWechat() {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) === "micromessenger") {
      return true;
    } else {
      return false;
    }
  }

  // 判断是否为支付宝浏览器
  isALiBrower() {
    if (/AlipayClient/.test(window.navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  // 获取设备类型
  deviceType() {
    const ua = navigator.userAgent;
    const agent = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    // eslint-disable-next-line
    for (let i = 0; i < len, (len = agent.length); i++) {
      if (ua.indexOf(agent[i]) > 0) {
        break;
      }
    }
  }

  // vant弹窗公用方法-alert
  alert(params = {}, submit) {
    Dialog.alert({
      title: params.title ?? "标题",
      message: params.msg ?? "弹窗内容",
      confirmButtonText: params.confirmButtonText ?? "确定",
      confirmButtonColor: params.confirmButtonColor ?? "#1989fa"
    }).then(() => {
      if (submit) submit();
    });
  }

  // vant弹窗公用方法-confirm
  confirm(params = {}, submit, cancel) {
    Dialog.confirm({
      title: params.title ?? "标题",
      message: params.msg ?? "弹窗内容",
      confirmButtonText: params.confirmButtonText ?? "确定",
      confirmButtonColor: params.confirmButtonColor ?? "#1989fa",
      cancelButtonText: params.cancelButtonText ?? "取消",
      cancelButtonColor: params.cancelButtonColor ?? "#000"
    })
      .then(() => {
        if (submit) submit();
      })
      .catch(() => {
        if (cancel) cancel();
      });
  }

  // vant弹窗公用方法-toast
  toast(params = {}, onClose) {
    Toast({
      type: params.type ?? "text",
      message: params.msg ?? "提示内容",
      icon: params.icon ?? "",
      mask: params.mask ?? false,
      duration: params.duration ?? "3000",
      onClose: onClose
    });
  }
  //主题类型
  getThemeList(name) {
    this.SetDic("ThemeList");
    let item_ = this.DIC.ThemeList.find(item => {
      return item.name == name;
    });
    return item_ ? item_.color : "#F57C00";
  }
  //套餐记录订单类型
  getOperatorType(type) {
    this.SetDic("operatorType");
    let item_ = this.DIC.operatorType.find(item => {
      return item.type == type;
    });
    return item_ ? item_.label : "未知";
  }
  //套餐记录订单类型
  getOrderType(type) {
    this.SetDic("orderType");
    let item_ = this.DIC.orderType.find(item => {
      return item.type == type;
    });
    return item_ ? item_.label : "未知";
  }
  //余额记录流水类型
  getFlowOrderType(type) {
    this.SetDic("flowType");
    let item_ = this.DIC.flowType.find(item => {
      return item.type == type;
    });
    return item_ ? item_.label : "未知";
  }
  // 机卡绑定工单状态
  getChangeImeiType(type) {
    this.SetDic("changeImeiType");
    let item_ = this.DIC.changeImeiType[type];
    return item_ ? item_ : "未知";
  }
  // 卡片状态
  getCardStatusType(type) {
    this.SetDic("cardStatus");
    let item_ = this.DIC.cardStatus.find(item => {
      return item.type == type;
    });
    return item_ ? item_.label : "未知";
  }
  //卡片状态弹窗展示  常规提醒文本
  getCardStatusText(type) {
    this.SetDic("cardStatus");
    let item_ = this.DIC.cardStatus.find(item => {
      return item.type == type;
    });
    return item_ ? item_.text : {};
  }
  //已断网 卡片状态弹窗展示 是DCP 且 已经实名
  getCardStatusRealNameText(type) {
    this.SetDic("cardStatus");
    let item_ = this.DIC.cardStatus.find(item => {
      return item.type == type;
    });
    return item_ ? item_.isRealNameText : {};
  }
  //已断网 卡片状态弹窗展示 是DCP 且 未实名 有套餐 用量超过90%
  getCardStatusUsageText(type) {
    this.SetDic("cardStatus");
    let item_ = this.DIC.cardStatus.find(item => {
      return item.type == type;
    });
    return item_ ? item_.usageText : {};
  }
  //已断网 卡片状态弹窗展示 是DCP 且 未实名 无套餐 为新卡
  getCardStatusNewCardText(type) {
    this.SetDic("cardStatus");
    let item_ = this.DIC.cardStatus.find(item => {
      return item.type == type;
    });
    return item_ ? item_.isNewCardText : {};
  }
		//格式化 运营商
		formatOperatorType(type){
			switch (type) {
				case '1':
					return '电信'
				case '2':
					 return '移动'
				case '3':
					return '联通'
				default:
					break;
			}
		}
}

export default new Tool();
