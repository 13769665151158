<template>
  <van-popup v-model:show="show" round closeable>
    <div class="scan_content_box">
      <div class="scan_content">
        <slot>
          <div class="scan_title">请选择条码类型！</div>
        </slot>
        <slot>
          <div class="choice">
            <div class="cancel" @click="cancel">取消</div>
            <div class="qrcode" @click="qrcode">二维码</div>
            <div class="barcode" @click="barcode">条形码</div>
          </div>
        </slot>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, toRefs } from "vue";
export default defineComponent({
  name: "ScanType",
  props: {},
  setup() {
    const state = reactive({
      iccid: "",
      show: false,
    });
    const { proxy:ctx } = getCurrentInstance();
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    const methods = {
      // 弹窗展示
      isShow() {
        state.show = true;
      },
      //弹窗取消
      cancel() {
        state.show = false;
      },
      //二维码
      qrcode() {
        ap.scan((res) => {
          state.show = false;
          ctx.$emit('scanCB',res.code);
        });
      },
      //条形码
      barcode() {
        ap.scan(
          {
            type: "bar",
          },
          (res) => {
            state.show = false;
            ctx.$emit('scanCB',res.code);
          }
        );
      }
    };
    return { ...toRefs(state), ...methods };
  },
});
</script>
<style lang="scss">
@import "~@/assets/style/mixin.scss";

.scan_content_box {
  display: flex;
  flex-direction: column;
  width: 615px;
  margin: 0 auto;
  height: 300px;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
    .exhibition {
      background: #2196f3;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        padding-left: 24px;
        img {
          width: 498px;
          height: 95px;
        }
      }

      .desc {
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .scan_content {
    flex: 1;
    overflow: auto;
    .scan_title {
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: center;
      height: 200px;
      line-height: 200px;
    }
    .choice {
      height: 98px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 98px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }
      .qrcode,
      .barcode {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #0bb20c;
      }
    }
  }
}
</style>
