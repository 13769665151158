
class SetupWebView {
    _setupWebViewJavascriptBridge(callback) {
        // eslint-disable-next-line no-undef
        if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge); }
        if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback); }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function () { document.documentElement.removeChild(WVJBIframe) }, 0)
    }
}
class NativeCallback extends SetupWebView {
    constructor() {
        super()
    }
    // 判断是否为Android或IOS true为Android false为IOS
    static _isAndroidorIos() {
        var u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;//g
        // eslint-disable-next-line no-irregular-whitespace    
        const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isAndroid) {
            return true
        } else if (isIOS) {
            return false
        }
    }

    /**
     * 
     * @param { String } fnName 方法名
     * @param { Object | String } data 传递参数
     * @param { Function } callBack 回调方法 
     */
    nativeIntercation(fnName, data = null, callBack) {
        const isAndroid = NativeCallback._isAndroidorIos()
        if (isAndroid) {
            const actions = new Map([
                ['sendPayInfo', () => window.android.receivePayInfo(data)]
            ])
            return actions.get(fnName).call(this)

        } else {
            const actions = new Map([
                ['sendPayInfo', () => super._setupWebViewJavascriptBridge(function (bridge) { bridge.callHandler('receivePayInfo', data) })]
            ])
            return actions.get(fnName).call(this)
        }
    }
}

export default new NativeCallback()