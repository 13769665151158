import MobileDetect from 'mobile-detect'
import { judgeBrand, arrModal } from './model';
var userAgent = navigator.userAgent;//获取userAgent信息  
var md = new MobileDetect(userAgent);
var os = md.os();//获取系统 
// 获取华为
// let HMS = ''
// if (userAgent.split(')')[0].includes('HMSCore')) {
//   HMS = userAgent.split(')')[0].split('HMSCore')[0].split(';');
// }
let model ; 
// model:手机型号
if (os == "iOS") {//ios系统的处理  
  model = md.mobile();
  if (model == "iPhone") {
    let iphoneArr = [
      ['430,932,3', 'A15', 'iPhone 14 Pro Max'],
      ['393,852,3', 'A15', 'iPhone 14 Pro'],
      ['375,812,3', 'A15', 'iPhone 13(12) min'],
      ['375,812,3', 'A15', 'iPhone 14'],
      ['428,926,3', 'A14', 'iPhone 13(12/13) Plus'],
      ['390,844,3', 'A14', 'iPhone 13(12/13)'],
      ['414,896,3', 'A13', 'iPhone 11 Pro Max(Xs Max)'],
      ['375,812,3', 'A13', 'iPhone 11 Pro(X/Xs)'],
      ['414,896,2', 'A13', 'iPhone 11(XR)'],
      ['414,736,3', 'A12', 'iPhone 8(7/6s) Plus'],
      ['375,667,2', 'A11', 'iPhone 8(7/6)'],
      ['320,568,2', 'A11', 'iPhone 5(s/se)'],
      ['320,480,2', 'A10', 'iPhone 4(s)'],
    ];
    
    // 获取GPU信息
    var canvas = document.createElement('canvas'),
    gl = canvas.getContext('experimental-webgl'),
    debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    let iphoneGPU = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    // alert(iphoneGPU)
    let width = window.screen.width;
    let height = window.screen.height;
    let devicePixelRatio = window.devicePixelRatio;
    let baseInfo = width + ',' + height + ',' + devicePixelRatio;
    let flag = false;
    for(let i in iphoneArr){
      for(let j in iphoneArr[i]){
        if(iphoneArr[i][0] == baseInfo && iphoneGPU.indexOf(iphoneArr[i][1]) != -1){
          model = iphoneArr[i][2];
          flag = true;
          break;
        }
      }
      if(flag){
        break;
      }
    }
    if(!flag){
      if((width == 375 && height == 812) || (width == 414 && height == 896)){
        model = 'iPhone X(11)'
      }else if(width == 360 && height == 780){
        model = 'iPhone 12'
      }else if(width == 390 && height == 840){
        model = 'iPhone 12 Pro'
      }else if(width == 428 && height == 926){
        model = 'iPhone 12 Pro Max'
      }
    }
  }else{
    model = "iPad"
  }
} else if (os == "AndroidOS") {//Android系统的处理  
  var j, sss = userAgent.split(";");
  for (var i = 0; i < sss.length; i++) {
    if (sss[i].indexOf("Build/") > 0) {
      j = i;
      break;
    }
  }
  if (j > -1) {
    model = sss[j].substring(0, sss[j].indexOf("Build/"));
  }
  arrModal.map((item) => {
    if (item.type == judgeBrand(userAgent.toLowerCase())) {
      item.modelNumber.map((vm) => {
        if (vm.lable == model?.trim()) {
          model = vm.value;
        }
      });
    }
  });
}
 
export default model

