<template>
  <div class="content_box_">
    <div class="appbar">
      <div class="exhibition">
        <div class="icon">
          <img src="../../assets/image/popup/icon_name_card.png" alt="" />
        </div>
        <div class="desc">设置昵称</div>
      </div>
    </div>
    <div class="content">
      <slot>
        <div class="msg">
          <input
            type="text"
            placeholder="请输入您的昵称"
            maxlength="10"
            v-model="afterUpdate.nickname"
          />
        </div>
      </slot>
      <slot>
        <div class="choice">
          <div class="cancel" @click="close">取消</div>
          <div class="submit" @click="submit">确定</div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
import { updateNickname } from "_API_/api.services";
export default defineComponent({
  name: "NameContainer",
  props: {},
  setup(props, context) {
    const state = reactive({
      card: "", //卡号
      afterUpdate: {
        show_notice: false, //弹框展示
        nickname: "", //修改后的昵称
        submit_show: false //确认是否修改的弹框控制展示
      } //昵称更新后
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $store: store } = ctx;
    const methods = {
      //取消修改
      close() {
        if (
          state.afterUpdate.nickname === "" ||
          state.afterUpdate.nickname !== ""
        ) {
          //通过emit传递昵称更新后的事件和对应的值
          context.emit("show", state.afterUpdate);
          state.afterUpdate["show_notice"] = false;
        }
      },
      //确定修改
      submit() {
        //确认修改的弹框控制展示,赋值为true
        state.afterUpdate.submit_show = true;
        if (state.afterUpdate.nickname !== "") {
          //修改昵称
          updateNickname({
            card: state.card,
            nickname: state.afterUpdate.nickname
          }).then(({ data: res }) => {
            if (res.code == 0) {
              tool.toast(
                {
                  msg: "修改成功",
                  duration: 1500
                },
                () => {
                  if (
                    state.afterUpdate.nickname !== "" &&
                    state.afterUpdate.submit_show
                  ) {
                    //通过emit传递昵称更新后的事件和对应的值
                    context.emit("show", state.afterUpdate);
                    state.afterUpdate["show_notice"] = false;
                  }
                }
              );
            }
          });
        } else {
          tool.toast(
            {
              msg: "请输入昵称",
              duration: 1500
            },
            () => {}
          );
        }
      }
    };
    onMounted(() => {
      //获取用户信息
      const info = store.state.user.userInfo;
      state.card = info.iccid;
    });
    return { ...toRefs(state), ...methods };
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";
@import "@/style/theme/index";
.content_box_ {
  display: flex;
  flex-direction: column;
  width: 615px;
  height: 500px;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
    .exhibition {
      @include background_color("background_color1");
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        padding-left: 24px;
        img {
          width: 498px;
          height: 95px;
        }
      }

      .desc {
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 15px;
      }
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .content {
    flex: 1;
    overflow: auto;
    .msg {
      height: 140px;
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 30px;
      text-align: center;
      line-height: 80px;
      input {
        width: 530px;
        height: 75px;
        border-radius: 100px;
        color: #aaaaaa;
        border: 1px solid #aaaaaa;
        text-align: center;
        margin-top: 20px;
      }
    }
    .choice {
      height: 88px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 88px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }
      .submit {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        @include font_color("font_color1");
      }
    }
  }
}
</style>
