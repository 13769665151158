<template>
  <div id="backDrop">
    <img src="../../assets/image/common/loading.gif" alt="" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "LoadingContainer",
  props: {},
  setup(prop, ctx) {
    return {};
  },
});
</script>

<style scoped>
#backDrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 999;
}
#backDrop img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
