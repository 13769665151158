<template>
  <div class="coupon-list-main">
    <div class="ul-coupon">
      <div class="li-row" v-for="item in dataList" :key="item.couponId">
        <div class="li-coupon" @click="toPath(item)">
          <div class="li-item"
            :style="{backgroundImage: 'url('+ (item.useStatus > 2 ? couponMaskImg : couponBgImg) +')'}">
            <div v-if="isRadio" @click.stop="handleCouponId(item)" class="radio-box">
              <img class="radio-icon" v-show="radioId == item.couponId && !isDisabled" :src="radioImg" alt="">
              <img class="radio-icon" v-show="(radioId != item.couponId) || isDisabled" :src="isDisabled ? disRadioImg : radioDefaultImg" alt="">
            </div>
            <div class="li-left">
              <p class="title" :class="{'maskColor':item.useStatus > 2}">{{item.couponTitle}}</p>
              <div class="desc" :class="{'maskColor':item.useStatus > 2}">
                有效期:&nbsp;
                <span v-if="item.expirationDate">{{item.expirationDate}}</span>
                <!-- <span v-else>{{`领取后${item.limitDay}天有效`}}</span> -->
              </div>
              <div class="desc f-center">
                <span class="text">{{item.ruleDesc}}</span>
                <span v-if="item.ruleDesc.length > 10" @click.stop="getExplain(item)">
                  <van-icon v-show="!item.showExplain" name="arrow-down" />
                  <van-icon v-show="item.showExplain" name="arrow-up" />
                </span> 
              </div>
            </div>
            <div class="li-right">
              <p class="price">
                ¥<span class="num">{{String(item.couponAmount).split('.')[0]}}</span>
                <span v-if="String(item.couponAmount).split('.')[1]">.{{String(item.couponAmount).split('.')[1]}}</span>
              </p>
              <div class="num">满{{item.fullUse}}可用</div>
              <div v-if="!isRadio || item.useStatus == 2"  class="btn" 
                :style="{color: btnColor}" 
                :class="{'maskColor':item.useStatus > 2,'maskBgColor': item.useStatus == 2}">
                {{item.useStatus == 2? '占用中' : '去使用'}}
                <van-icon name="arrow" v-show="item.useStatus != 2" />
              </div>
            </div>
          </div>
          <div class="coupon-tip" v-if="item.showExplain">
            <span :class="{'maskColor':item.useStatus > 2}" v-html="item.ruleDesc"></span>
          </div>
          <img v-if="item.useStatus > 2" class="mask-icon" :src="handleImg(item.useStatus)" alt="">
        </div>
      </div>
      <div class="no-li" v-if="!isRadio">没有更多了~</div>
    </div>
  </div>
</template>

<script>
  import { defineComponent,getCurrentInstance, reactive,toRefs, onMounted } from "vue";
  export default defineComponent({
    name: 'CouponList',
    props: {
      isRadio: {
        type: Boolean,
        default: false,
      },
      dataList: {
        type: Array,
        default: [], 
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },
    setup(prop,context){
      const state = reactive({
        couponBgImg: require('../../assets/image/coupon/anfang-coupon-bg.png'),
        couponMaskImg: require('../../assets/image/coupon/coupon-bg-icon-dis.png'),
        refundImg: require('../../assets/image/coupon/coupon-icon-01.png'),
        usedImg: require('../../assets/image/coupon/coupon-icon-02.png'),
        laterImg: require('../../assets/image/coupon/coupon-icon-03.png'),
        btnColor: '#FF271D', // #FF564E
        radioImg: '',
        radioDefaultImg: require('../../assets/image/coupon/radio-icon-default.png'),
        disRadioImg: require('../../assets/image/coupon/dis-coupon-radio.png'),
        platFormTheme: '',
        radioId: '',
      })
      const { proxy: ctx } = getCurrentInstance();
      const { $tool: tool, $router: router, $store: store, $http: http } = ctx;

      onMounted(() => {
        let val = store.state.common.theme
        state.platFormTheme = val;
        if(val == 'AnFang'){
          state.couponBgImg = require('../../assets/image/coupon/anfang-coupon-bg.png')
          state.btnColor = '#FF564E'
          state.radioImg = require('../../assets/image/coupon/anfang-radio-icon.png')
        }else if(val == 'YuYin'){
          state.couponBgImg = require('../../assets/image/coupon/yuyin-coupon-bg.png')
          state.btnColor = '#FF271D'
          state.radioImg = require('../../assets/image/coupon/yuyin-radio-icon.png')
        }else {
          state.couponBgImg = require('../../assets/image/coupon/anfang-coupon-bg.png')
          state.btnColor = '#FF564E'
          state.radioImg = require('../../assets/image/coupon/zhongxun-radio-icon.png')
        }
      })

      const methods = {
        toPath(item){
          if(item.useStatus != 1) return;
          if(state.platFormTheme == 'AnFang'){
            router.replace({
              name: 'af-platfrom'
            })
          }else if(state.platFormTheme == 'YuYin'){
            router.replace({
              name: 'yy-platfrom'
            })
          }else {
            router.push({
              name: "personal-packageRecharge", //前往套餐充值
              query: { type: 0 }
            });
          }
        },
        handleCouponId(item){
          if(ctx.isDisabled) return;
          if(item.couponId === ctx.radioId){
            state.radioId = '';
          }else {
            state.radioId = item.couponId;
          }
          let obj = {
            couponDistributionRecordId: state.radioId ? item.couponId : '',
            postCouponPrice: state.radioId ? item.postCouponPrice : '',
            discountAmount: state.radioId ? item.couponAmount : '',
          }
          context.emit('UpdateCoupon',obj)
        },
        handleDefaultCouponId(){
            const [{ couponAmount, couponId } = { couponAmount: 0 }] = ctx.dataList.filter((fItem) => {
            //    return ctx.radioId != fItem.couponId
                return fItem.isDefault == 1
            })
            state.radioId = couponId
            console.log('state.radioId:',state.radioId);
            let obj = {
                couponDistributionRecordId: couponId,
                // postCouponPrice: item.postCouponPrice,
                discountAmount: couponAmount,
            }
            context.emit('UpdateDefaultCoupon',obj)
        },
        handleImg(status){
          if(status == 3){
            return state.usedImg
          }
          if(status == 4){
            return state.laterImg
          }
          return state.refundImg
        },
        getExplain(item){
          if(item.ruleDesc){
            item.showExplain = !item.showExplain
          }
        },
      }

      return {
        ...toRefs(state),
        ...methods,
      }
    }
  })
</script>

<style lang="scss" scoped>
  .ul-coupon {
    width: 100%;
    height: 100%;
    // background: #F1F5FB;
    // overflow-y: auto;
    // .li-row:last-child {
    //   .li-coupon:last-child {
    //     margin-bottom: 0px;
    //   }
    // } 
    .no-li {
      padding: 36px 0px 66px;
      text-align: center;
      color: rgba(0,0,0,0.3);
      font-size: 24px;
    }
  }
  .li-coupon {
    margin-bottom: 30px;
    background: #fff;
    border-radius: 8px;
    position: relative;
  }
  .maskColor {
    color: rgba(0,0,0,0.4)!important;
  }
  .maskBgColor {
    background: rgba(255,255,255,0.5)!important;
  }
  .f-center {
    display: flex;
    align-items: center;
  }
  .li-item {
    width: 100%;
    min-height: 204px;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    .radio-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;
      .radio-icon {
        width: 36px;
        height: 36px;
      }
    }
    .li-left {
      flex: 1;
      padding: 30px 28px 20px;
      box-sizing: border-box;
      color: rgba(0,0,0,0.4);
      font-size: 24px;
      .title {
        max-width: 440px;
        height: 90px;
        font-size: 14px;
        color: rgba(0,0,0,0.8);
        font-size: 32px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 20px;
      }
      .desc {
        margin-bottom: 14px;
        .text {
          max-width: 280px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .van-icon-arrow-down,.van-icon-arrow-up {
          margin-top: 3px;
          margin-left: 4px;
        }
      }
    }
    .li-right {
      width: 196px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 24px;
      color: #fff;
      .price {
        .num {
          font-size: 56px;
          font-weight: 500;
          margin-left: 6px;
        }
      }
      .num {
        line-height: 34px;
        margin-bottom: 10px;
      }
      .btn {
        width: 126px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 126px;
        color: #FF564E;
        padding-left: 4px;
        .van-icon-arrow {
          margin-top: 2px;
        }
      }
    }
  }

  .coupon-tip {
    width: 100%;
    border-top: 2px dashed #EAEDF2;
    font-size: 24px;
    color: rgba(0,0,0,0.8);
    padding: 28px;
    box-sizing: border-box;
  }
  .mask-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 148px;
    height: 116px;
  }
</style>