<template>
  <div class="wrapPop">
    <van-popup v-model:show="show" :close-on-popstate='true'>
      <div class="container">
        <van-cell-group>
          <van-field
            v-model="card"
            readonly
            required
            label="ICCID:"
          />
          <van-field
            v-model.trim="imei"
            required
            label="IMEI:"
            placeholder="请输入IMEI"
            maxlength="25"
          />
        </van-cell-group>
        <div class="tip">
          <span>IMEI查询方法:<br>1、拨号盘输入*#06#<br>2、可以通过百度查询IMEI查询方法。</span><br>
					<span>提示:双卡槽手机请提供卡对应卡槽的IMEI号</span>
        </div>
        <van-button type="primary" size="large" block class="submit" @click="submit" :disabled='imei.length<15 || imei.length>20'>提交</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  getCurrentInstance
} from "vue";
import { submitChangeImei } from "_API_/api.services";
export default defineComponent({
  name: "changeImei",
  props: {
    card: {
      type: String,
      default: ""
    }
  },
  setup() {
    const state = reactive({
      show:false,
      imei:''
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    const methods = {
      //弹框展示
      isShow() {
        state.show = true;
      },
      submit(){
        submitChangeImei({
          card: state.card,
					imei: state.imei
        }).then(({data})=>{
          if(data.code!=0){
            return tool.toast({
              msg:data.msg
            })
          }
          return tool.alert({
            title:'提示',
            msg:'提交成功，请等待系统处理'
          },()=>{
            state.show = false
            ctx.$emit('refreshData')
          })
        })
      }
    };
    onMounted(() => {
      
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
});
</script>
<style lang="scss" scoped>
.wrapPop{
  /deep/ .van-popup{
    /deep/ .container{
      width: 85vw;
      padding: 20px 3%;
      box-sizing: border-box;
      border-radius: 5px;
      /deep/ .van-cell__title {
        width: 3em;
      }
      .tip{
        padding: 5px 3%;
        font-size: 22px;
        color: #999;
      }
      .submit{
        height: 75px;
        line-height: 75px;
        margin-top: 15px;
        font-size: 24px;
      }
    }
  }
}

</style>
