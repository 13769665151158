<template>
  <div class="empty-index">
    <div class="data-list-empty">
      <img :src="emptyImg" alt="">
      <div>{{emptyText || '什么都没有'}}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent,getCurrentInstance, reactive,toRefs, onMounted } from "vue";

  export default defineComponent({
    name: 'EmptyList',
    props: {
      emptyText: {
        type: String,
        default: ""
      }
    },
    setup(){
      const state = reactive({
        emptyImg: '',
      })
      const { proxy: ctx } = getCurrentInstance();
      const { $store: store, } = ctx;

      onMounted(()=>{
        let val = store.state.common.theme
        if(val == 'AnFang'){
          state.emptyImg = require('../../assets/image/common/anfang-data-list-0.png')
        }else if(val == 'YuYin'){
          state.emptyImg = require('../../assets/image/common/yuyin-data-list-0.png')
        }else {
          state.emptyImg = require('../../assets/image/common/zhongxun-data-list-0.png')
        }
      })

      return {
        ...toRefs(state)
      }
    }
  })
</script>

<style lang="scss" scoped>
  .data-list-empty {
    width: 100%;
    padding-top: 280px;
    text-align: center;
    color: rgba(0,0,0,0.6);
    font-size: 28px;
    img {
      width: 354px;
    }
  }
</style>