<template>
    <section>
        <van-popup v-model:show="couponShow"  position="bottom" >
            <div class="coupon-dialog">
            <div class="coupon-head">
                <div :class="['head-item',headType == 1?`${className}`:'']" @click="handleType(1)">可用({{availableCouponList.length}})</div>
                <div :class="['head-item',headType == 2?`${className}`:'']" @click="handleType(2)">不可用({{notAvailableCouponList.length}})</div>
            </div>
            <div class="coupon-list">
                <coupon-list 
                ref="couponListRef"
                :isRadio="true" 
                :isDisabled="headType == 1?false:true"
                @UpdateCoupon="UpdateCoupon"
                @UpdateDefaultCoupon="UpdateDefaultCoupon"
                :dataList="headType ==1 ? availableCouponList : notAvailableCouponList">
                </coupon-list>
                <empty-list v-if="headType ==1 ? availableCouponList.length == 0 : notAvailableCouponList.length == 0"></empty-list>
            </div>
            <div class="dialog-footer">
                <div :class="['btn',`${btnClassName}`]" @click="submitCoupon()">确定</div>
            </div>
            </div>
        </van-popup>
        <coupon-list 
            v-if="autoSelect" 
            ref="couponListRef2"
            :isRadio="true" 
            :isDisabled="headType == 1?false:true"
            @UpdateCoupon="UpdateCoupon"
            @UpdateDefaultCoupon="UpdateDefaultCoupon"
            :dataList="headType ==1 ? availableCouponList : notAvailableCouponList">
        </coupon-list>
    </section>
</template>

<script>
  import { reactive, getCurrentInstance,toRefs, defineComponent,nextTick } from 'vue'
  
  export default defineComponent({
    name: 'CouponDialog',
    setup(prop,context){
      const state = reactive({
        couponShow: false,
        headType: 1,
        className: 'anfang-item-act',
        btnClassName: 'anfang-btn',
        packageObject: {},
        availableCouponList: [], // 可用的
        notAvailableCouponList: [], // 不可用的
        couponObj: {},
        autoSelect: false
      })
      const { proxy: ctx } = getCurrentInstance();
      const { $tool: tool, $router: router, $store: store, $http: http } = ctx;

      const methods = {
        UpdateCoupon(val){
          console.log(JSON.stringify(val))
          state.couponObj = val || {};
        },
        UpdateDefaultCoupon(val){
          console.log('微信自动续费',JSON.stringify(val))          
          context.emit('UpdataCoupon',val || {});
        },  
        submitCoupon(){
          context.emit('UpdataCoupon',state.couponObj);
          state.couponShow = false;
        },
        init(obj, couponObj = '',payType){
          state.packageObject = obj ? JSON.parse(JSON.stringify(obj)) : {};
          state.couponObj = couponObj || {} 
          state.couponShow = true;
          let val = store.state.common.theme
          if(val == 'AnFang'){
            state.className = 'anfang-item-act'
            state.btnClassName = 'anfang-btn'
          }else if(val == 'YuYin'){
            state.className = 'yuyin-item-act'
            state.btnClassName = 'yuyin-btn'
          }else {
            state.className = 'zhongxun-item-act'
            state.btnClassName = 'zhongxun-btn'
          }
          ctx.getSelectCoupon({},payType);
        },
        handleType(type){
          if(type == state.headType) return;
          state.headType = type;
        },
        getSelectCoupon(setMealObject = {}, payType){ // 1 普通续费 2签约自动续费
          const info = store.state.user.userInfo
          http.post('/card/getCoupons',{
            card: info.iccid,
            packageId: setMealObject.packageId || state.packageObject.packageId,
            payType
          }).then(({data:res})=>{
            if(res.code == 0){
              let result = res.data;
              state.availableCouponList = result.availableCouponList || []
              state.notAvailableCouponList = result.notAvailableCouponList || []
              state.availableCouponList.forEach(v=>{
                v.showExplain = false;
                v.couponId = v.id;
              })
              state.notAvailableCouponList.forEach(v=>{
                v.showExplain = false;
                v.couponId = v.id;
              })              
              ctx.$nextTick(()=>{
                console.log('setMealObject---',setMealObject);
                // 普通续费、签约自动续费 ->没打开优惠券面板 
                if(setMealObject.packageId && [1,2].includes(payType)){                      
                    ctx.$refs.couponListRef2.handleDefaultCouponId()         
                }
                // 普通续费、签约自动续费 ->打开优惠券面板 
                if(!setMealObject.packageId && [1,2].includes(payType)){
                    ctx.$refs.couponListRef.radioId = state.couponObj.couponDistributionRecordId;      
                }                   
              })
              return;
            }
            state.notAvailableCouponList = []
            state.availableCouponList = []
          })
        },
      }

      return {
        ...toRefs(state),
        ...methods,
      }
    }
  })
</script>

<style lang="scss" scoped>
  .coupon-dialog {
    background: #F1F5FB;
    height: 1140px;
  }
  .coupon-head {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 40px 32px 0px;
    box-sizing: border-box;
    .head-item {
      width: 50%;
      height: 64px;
      text-align: center;
      padding-bottom: 16px;
      box-sizing: border-box;
      color: rgba(0,0,0,0.4);
      font-size: 32px;
    }
    .anfang-item-act {
      color: #377DFE;
      border-bottom: 2px solid #377DFE;
    }
    .yuyin-item-act {
      color: #FE3A39;
      border-bottom: 2px solid #FE3A39;
    }
    .zhongxun-item-act {
      color: #18B2A3;
      border-bottom: 2px solid #18B2A3;
    }
  }
  .coupon-list {
    padding: 32px 32px 140px;
    box-sizing: border-box;
    height: calc(1140px - 104px);
    overflow-y: auto;
  }
  .dialog-footer {
    background: #F1F5FB;
    width: 100%;
    padding: 0px 32px 56px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 11;
    .btn {
      width: 100%;
      height: 84px;
      text-align: center;
      line-height: 84px;
      color: #fff;
      font-size: 32px;
    }
    .anfang-btn {
      border-radius: 24px;
      background: rgba(55,125,254,1);
      box-shadow: 0 8px 12px 0 rgba(188,225,255,1);
    }
    .yuyin-btn {
      border-radius: 16px;
      background: linear-gradient(90deg, rgba(255,88,75,1) 0%, rgba(254,30,20,1) 100%);
    }
    .zhongxun-btn {
      border-radius: 24px;
      background: rgba(24,178,163,1);
      box-shadow: 0 8px 12px 0 rgba(188,225,255,1);
    }
  }
</style>