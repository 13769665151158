import axios from "axios";
import store from "../../store/index";
var instance = axios.create({
  headers: { "Content-Type": "application/json" },
  timeout: 30000,
  baseURL: window.SITE_CONFIG.apiURL
});

/**
 * 封装所有请求
 * @param url
 * @param method
 * @param data
 * @param headers
 * @returns {Promise}
 */
export function request(url, method, data = {}, headers, timeout=30000) {
  return new Promise((resolve, reject) => {
    instance({
      url: url,
      method: method || "post",
      params: method === "get" ? data : "",
      data: method !== "get" ? data : "",
      headers: headers || {
        "Content-Type": "application/json"
      },
      timeout
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

let requestCount = 0
let appointUrlNoLoading = false
instance.interceptors.request.use(
  config => {
    requestCount++
    if (!config.noLoading) {
      //分发SetRequest这个action到vuex，并携带true
      // 视频认证页面 不适用全局loading 使用 自定义
      if (!location.href.includes('new-auth-telecom')) {
        store.dispatch("SetRequest", true);
      } 
    } else {
        appointUrlNoLoading = config.noLoading        
    }
    return config;
  },

  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    requestCount--
    setTimeout(() => {
      //分发SetRequest这个action到vuex，并携带false      
      appointUrlNoLoading && store.dispatch("SetRequest", false)
      requestCount == 0 && store.dispatch("SetRequest", false)
    }, 100);
    return response;
  },
  error => {
    requestCount--
    //分发SetRequest这个action到vuex，并携带false
    store.dispatch("SetRequest", false);
    return Promise.reject(error);
  }
);

export default instance;
