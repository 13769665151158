import {
  Area,
  Form,
  Swipe,
  SwipeItem,
  Divider,
  Progress,
  Button,
  List,
  Cell,
  Tabbar,
  TabbarItem,
  Icon,
  NavBar,
  Field,
  ActionSheet,
  Uploader,
  Circle,
  Grid,
  GridItem,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Popup,
  Picker,
  PullRefresh,
  RadioGroup,
  Radio,
  CellGroup,
  Tag,
  Popover,
  Loading,
  Overlay,
	checkbox,
	CheckboxGroup,
	Row,
	Stepper,
  Dialog,
  Empty,
  CountDown,
  NoticeBar,
} from "vant";

const plugins = [
  Area,
  Tab,
  Tabs,
  Form,
  Swipe,
  SwipeItem,
  Divider,
  Progress,
  Button,
  List,
  Cell,
  Tabbar,
  TabbarItem,
  Icon,
  NavBar,
  Field,
  ActionSheet,
  Uploader,
  Circle,
  Grid,
  GridItem,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Popup,
  Picker,
  PullRefresh,
  RadioGroup,
  Radio,
  CellGroup,
  Tag,
  Popover,
  Loading,
  Overlay,
	checkbox,
	CheckboxGroup,
	Row,
	Stepper,
  Dialog,
  Empty,
  CountDown,
  NoticeBar,
];
export const vantPlugins = {
  install: function(vm) {
    plugins.forEach(item => {
      vm.component(item.name, item);
    });
  }
};
