<template>
  <van-popup
    v-model:show="show"
    round
    closeable
    teleport="body"
		class="modifyPopup"
  >
    <div class="adDialog">
      <img
        class="img"
        @click="toChangeCard"
        :src="homeAdBannerImg?homeAdBannerImg:require('_ASSETS_/image/personal/changeCardAd.png')"
        alt=""
      />
    </div>
  </van-popup>
</template>

<script>

import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
import { advertLog } from "_API_/api.services";
export default defineComponent({
  name: "adChangeCardDialog",
  setup() {
    const state = reactive({
      show: false,
      info:{},
      pageData:{},
      homeAdBannerImg:"",
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $router: router, $store: store } = ctx;
  
    const methods = {
      // 弹窗展示
      init(data) {
        state.show = true;
        state.pageData = data
        if(state.pageData.bannerMap[state.pageData['homeAdBannerId']]){
          state.homeAdBannerImg = state.pageData.bannerMap[state.pageData['homeAdBannerId']]
        }
      },
      // 去换卡
      toChangeCard() {
				store.dispatch("SetUserInfo", {isPackage: false})
        advertLog({
          param: state.info.iccid,
          type:15,
          browserType: state.info.device
        })
        router.push({
          name: "personal-changecard",
          query:{
            bannerData:JSON.stringify(state.pageData)
          }
        });
      }
    };
    onMounted(()=>{
      state.info = store.state.user.userInfo;
    })
    return { ...toRefs(state), ...methods };
  }
});
</script>
<style lang="scss">
.modifyPopup{
	background-color:transparent !important;
}
</style>
