<template>
  <div v-if="isWhiteList">
    <van-dialog v-model:show="isShow" title="修改白名单" :show-cancel-button="cancelButton ? cancelButton : false"
      show-confirm-button :confirm-button-text="buttonName" confirm-button-color="red" @cancel="onCancel"
      @confirm="onConfirm">
      <div style="padding:12px 20px 16px 20px">
        <van-cell-group inset>
          <van-field class="input_width" :border="false"  v-model="afterUpdate.nickname" label="昵称："
            :placeholder="name" />
          <van-field :border="false"  v-model="afterUpdate.telNum" label="电话号码："  maxlength="20" :placeholder="mobile"
            v-if="status == 0" />
        </van-cell-group>
      </div>
    </van-dialog>
  </div>
  <div v-else>
    <van-dialog v-model:show="isShow" :title="title" :show-cancel-button="cancelButton ? cancelButton : false"
      show-confirm-button :confirm-button-text="buttonName" :confirm-button-color="theme == 'AnFang'?'#377DFE':'red'" @cancel="onCancel"
      @confirm="onConfirm">
      <div style="padding:12px 20px 16px 20px">{{ content }}</div>
    </van-dialog>
  </div>

</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted
} from 'vue'
import { Dialog } from 'vant';
export default defineComponent({
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  name: 'TipPopup',
  props: {
    title: {
      type: String,
      default: '提示'
    },
    buttonName: {
      type: String,
      default: '确认'
    },
    content: {
      type: String,
      default: '确认'
    },
    cancelButton: {
      type: Boolean,
      default: true
    },
    isWhiteList: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    mobile: {
      type: String,
      default: ""
    },
    status: {
      type: Number,
      default: 0
    }
  },

  setup(prop, context) {
    const state = reactive({
      theme: '', // 主题名称
      isShow: true,
      afterUpdate: {
        show_notice: false, //弹框展示
        nickname: "", //修改后的昵称
        telNum: "", //修改后的手机号码
        submit_show: false //确认是否修改的弹框控制展示
      } //昵称更新后
    })
    // 这里的ctx  类似于vue2的ctx
    const { proxy: ctx } = getCurrentInstance()
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx
    const methods = {
      onConfirm() {
        if (ctx.isWhiteList) {
          console.log({ ...state.afterUpdate })
          context.emit("onConfirm", { ...state.afterUpdate });
        } else {
          context.emit("onConfirm");
        }

      },
      onCancel() {
        context.emit("onCancel");
      },
      close() {
        //通过emit传递昵称更新后的事件和对应的值
        context.emit("show", state.afterUpdate);
        state.afterUpdate["nickname"] = "";
      },
    }

    onMounted(() => {
      // 获取主题名称
      state.theme = store.state.common.theme
      // 获取用户信息
      const info = store.state.user.userInfo
      // 注销时展示手机号码
      state.mobile = info.mobile || ''
      state.cardId = info.cardId || '' // 获取卡Id
      state.card = info.virtualId || info.iccid || '' // 获取卡号
      state.virtualId = info.virtualId
      state.iccids = info.iccids // 用户绑定的卡片列表
    })
    return {
      ...toRefs(state),
      ...methods,
      tool
    }
  }
})
</script>
<style lang="scss" scoped>
@import "@/style/theme/index";

/deep/.van-dialog__header {
  font-weight: 700 !important;
  font-size: 32px !important;
}

/deep/.van-cell-group {
  margin: 0px !important;
}

/deep/.van-field {
  background: rgba(0, 0, 0, 0.03) !important;
  border-radius: 8px !important;
}

/deep/.van-field:last-child {
  margin-top: 24px;
}

/deep/.van-field__label {
  max-width: 140px;
}

.input_width {
  /deep/.van-field__label {
    width: 90px;
  }
}
</style>
