import request from 'axios'
import instance from "_UTIL_/modules/http";
let baseURL 
let wxAuthURL
switch (window.SITE_CONFIG['nodeEnv']) {
  case 'dev':
    baseURL = `http://mall.test.xiaoyizuji.com`
    wxAuthURL = "http://web-dev.zuji.nbm2m.com/lease-api/api"
    break;
  case 'prod:test':
    baseURL = `http://mall.test.xiaoyizuji.com`
    wxAuthURL = "https://zmp-test.nbm2m.com/api/maker"
    break;
  case 'prod':
    baseURL = `http://mall.xiaoyizuji.com`
    wxAuthURL = "http://web-dev.zuji.nbm2m.com/lease-api/api"
    break;
  default:
    baseURL = `http://mall.xiaoyizuji.com`
    wxAuthURL = "https://zmp-prod.163-iot.cn/api/maker"
    break;
}
export const baseUrl = baseURL
export const wxAuthUrl = wxAuthURL

// 获取小亿优选 入口
export const getShopInfo = (data) => {
  return request({
    url: baseURL + `/lease-api/api/agentContract/getAgentInfo`,
    method: "post",
    data
  })
}

// 订单 大于0 即有购买记录 则 弹窗显示
export const queryPurchaseRecord = (data) => {
  return request({
    url: baseURL + `/lease-api/api/order/queryPurchaseRecord`,
    method: "post",
    data
  })
}
// 查询是否展示分销赚钱入口
export const agentShow = iccid => {
  return instance({
    url: `/api/agent/earn/${iccid}`,
    method: "get",
  });
};

// 查询用户手机号
export const getUserMobile = (cardId) => {
  return instance({
    url: `/card/getUserMobile?cardId=${cardId}`,
    method: "get",
    // data
  });
};

// 查询用户数据统计报表
export const queryCardCurrentTrafficOfUesd = (data) => {
  return instance({
    url: `/card/v2/queryCardCurrentTrafficOfUesd`,
    method: "post",
    data
  });
};

