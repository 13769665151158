import { getStore, removeStore, setStore } from "@/utils/store";
let user = {
  state: {
    tongLianInfo: getStore({
      name: "tongLianInfo"
    }) || {},
    isSimple: getStore({
      name: "isSimple"
    }) || 0,
    loginAccessToken:
      getStore({
        name: "loginAccessToken"
      }) || "",
    queryIccid: "",
    queryVirtualId: "",
    userInfo: {},
    userList: getStore({ name: "userList" }) || []
  },
  actions: {
    //用户登录token
    SetLoginToken({ commit }, data) {
      commit("SET_LOGIN_TOKEN", data);
    },
    //工单查询
    QueryOrder({ commit }, data) {
      commit("Query_Order", data);
    },
    //用户列表
    SetUserList({ commit }, data) {
      commit("SET_USER_LIST", data);
    },
    //用户信息
    SetUserInfo({ commit }, data) {
      commit("SET_USER_INFO", data);
    },
    //用户退出
    UserLogout({ commit }) {
      commit("SET_USER_LOGOUT");
    }
  },
  mutations: {
    SET_LOGIN_TOKEN: (state, loginAccessToken) => {
      state.loginAccessToken = loginAccessToken;
      setStore({
        name: "loginAccessToken",
        content: state.loginAccessToken,
        type: "session"
      });
    },
    Query_Order: (state, data) => {
      Object.assign(state, {
        queryIccid: data.iccid,
        queryVirtualId: data.virtualId
      });
    },
    SET_USER_LIST: (state, userList) => {
      state.userList = userList;
      setStore({
        name: "userList",
        content: JSON.parse(JSON.stringify(state.userList))
      });
    },
    SET_USER_INFO: (state, userInfo) => {
      for (let key in userInfo) {
        state.userInfo[key] = userInfo[key];
      }
      setStore({
        name: "userInfo",
        content: state.userInfo,
        type: "session"
      });
    },
    SET_USER_LOGOUT: (state) => {
      delete state.userInfo.mobile
      removeStore({
        name: "userInfo",
        type: "session"
      });
      removeStore({ name: "loginAccessToken", type: "session" });
    },
    Set_Item_Type: (state, isSimple) =>{
      state.isSimple = Number(isSimple) 
      setStore({
        name: "isSimple",
        content: state.isSimple,
        type: "session"
      });
    },
    Set_TongLian_Info: (state, tongLianInfo) =>{
      for (let key in tongLianInfo) {
        state.tongLianInfo[key] = tongLianInfo[key];
      }
      setStore({
        name: "tongLianInfo",
        content: state.tongLianInfo
      });
    }
  },
  modules: {}
};

//获取存储在SessionStorage的用户信息
let info = getStore({ name: "userInfo" });
//遍历用户信息存储于state
for (let key in info) {
  user.state.userInfo[key] = info[key];
}

export default user;
