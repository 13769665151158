<template>
  <van-popup
    v-model:show="show"
    round
    closeable
    teleport="body"
    @closed="cancel"
		class="modifyPopup"
  >
    <div class="adDialog">
      <img
        class="img"
        @click="clickAd"
        :src="require('_ASSETS_/image/adDialog/hdPop.png')"
        alt=""
      />
    </div>
  </van-popup>
</template>

<script>
// 目前弹广告只是针对互电引流做的 具有针对性，后期后台对接配置广告时需修改

import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
import { advertLog } from "_API_/api.services";
import { isDevice } from "@/utils/utils";
export default defineComponent({
  name: "adDialog",
  setup() {
    const state = reactive({
      url: "",
      show: false,
      info: null
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    //检测用户设备
    state.device = isDevice();
    const methods = {
      // 弹窗展示
      init(url) {
        state.url = url;
        state.show = true;
      },
      //弹窗取消
      cancel() {
        ctx.$emit("adDialogClose");
      },
      clickAd() {
        ctx.advertLogFn(8, () => {
          location.href =
            "http://a.app.qq.com/o/simple.jsp?pkgname=com.hud666.hd&ckey=CK1478634096408";
        });
      },
      advertLogFn(type, cb = () => {}) {
        advertLog({
          param: state.info.iccid,
          type,
          browserType: state.device
        }).then(() => {
          cb();
        });
      }
    };
    onMounted(() => {
      //获取用户信息
      state.info = store.state.user.userInfo;
      methods.advertLogFn(2);
    });
    return { ...toRefs(state), ...methods };
  }
});
</script>
<style lang="scss">
@import "~@/assets/style/mixin.scss";
.adDialog {
  .img {
    width: 75vw;
  }
}
</style>
