<template>
  <wx-open-launch-weapp
    id="launch-btn"
    appid="wxa5b3db0cd2c5a1ed"
    username="gh_5eac63f4d504"
    :path="pathName"
  >
    <div v-is="'script'" type="text/wxtag-template" style="display: block">
      <!-- <style>.btn { padding: 12px }</style> -->
      <button
        style="
          border: none;
          background: #fff;
          color: black;
          font-size: 14px;
          margin-top: 14px;
        "
      >
        {{ btnText }}
      </button>
      <!-- <span>{{ btnText }}</span> -->
    </div>
  </wx-open-launch-weapp>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  getCurrentInstance,
  onMounted,
} from 'vue'
export default defineComponent({
  name: 'LaunchBtn',
  props: {
    btnText: {
      type: String,
      default: () => {
        return '实名认证'
      },
    },
    iccid: {
      type: String,
      default: () => {
        return ''
      },
    },
    customStyle: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  setup() {
    const state = reactive({
      pathName: '',
    })
    const { proxy: ctx } = getCurrentInstance()
    const { $router: router, $store: store } = ctx

    const methods = {}
    onMounted(() => {
      state.pathName = '/pages/login/index?iccid=' + ctx.iccid
    })
    return {
      ...toRefs(state),
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
</style>