<template>
  <div class="platfrom-card-index">
    <van-popup 
      v-model:show="cardPopup" 
      :close-on-click-overlay="false" 
      position="bottom"
      closeable
      close-icon="close"
      :title="`绑定卡片共 ${cardList} 张`"
      close-icon-position="top-right"
    >
      <div class="popup-content">
        <div class="card-title">
          <span>绑定卡片 共{{cardList?.length}}张</span>
        </div>
        <div class="card-list">
          <div class="ul-card">
            <div class="li-card" v-for="item in cardList" @click="loginItemCard(item)"  :key="item.cardId">
              <div class="li-l">
                <img :src="maps[item.operators]" alt="">
              </div>
              <div class="li-r">
                <div style="color: rgba(0,0,0,.85)">{{ operatorsNames[item.operators]}}</div>
                <div style="margin-top: 8px;color: rgba(0,0,0,.5)">卡号：{{item.iccid}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { reactive, toRefs,defineComponent,getCurrentInstance } from 'vue' 
  
  import mobile from '../../assets/image/personal/ChinaMobile.png';
  import telecom from '../../assets/image/personal/ChinaTelecom.png';
  import unicom from '../../assets/image/personal/ChinaUnicom.png';
  // import jh from '../../assets/image/personal/nationalMobile.png';
  export default defineComponent({
    name: "AfChangeCard",
    setup() {
      const state = reactive({
        cardPopup: false,
        cardList: [],
        maps: {
          1: telecom,
          2: mobile,
          3: unicom,
          4: telecom,
        },
        operatorsNames: {
          1: '中国电信',
          2: '中国移动',
          3: '中国联通',
          4: '聚合卡',
        }
      })
      const {proxy: ctx} = getCurrentInstance()
      const { $router: router, $store: store, } = ctx;

      const methods = {
        init(list = []){
          state.cardPopup = true;
          state.cardList = list;
        },
        closePop() {
          state.cardPopup = false;
        },
        loginItemCard (item) {
          this.$emit('loginHandler', item.iccid)
          ctx.$refs.afChangeCard.init(cardList)
        }
      }

      return { 
        ...toRefs(state), 
        ...methods,
      };
    }
  })

</script>

<style lang="scss" scoped>
  .popup-content {
    background-color: #FFF;
    width: 100% !important;
    padding-top: 0;
    border-radius: 16px;
  }
  .card-title{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0,0,0,.45);
  }
  .close-img {
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: right;
    img {
      width: 56px;
      height: 56px;
    }
  }
  .popup-content {
    width: 660px;
    padding: 0px 30px;
    box-sizing: border-box;
    position: relative;
    font-family: "PingFang SC";
    .card-list {
      width: 100%;
      height: 100%;
      padding: 0px;
      box-sizing: border-box;
      margin-bottom: -2px;
      .head-img {
        width: 100%;
        margin-bottom: -10px;
      }
      .ul-card {
        width: 100%;
        // padding: 0 20px 16px;
        max-height: 520px;
        overflow-y: auto;
        box-sizing: border-box;
        .li-card {
          width: 100%;
          height: 130px;
          // padding: 0px 16px;
          // background: linear-gradient(133.9deg, rgba(255,255,255,1) 0%, rgba(255,244,223,1) 100%);
          border: 1px solid #eee;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          border-radius: 16px;
          .li-l {
            font-size: 24px;
            color: #6F5B38;
            height: 95%;
            padding-top: 14px;
            box-sizing: border-box;
            width: 122px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 48px;
              height: 48px;
              display: inline-block;
            }
          }
          .li-r {
            padding-left: 16px;
            font-size: 24px;
            // background: green;
            flex: 1;
            height: 95%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }
        }
      }
    }
    .card-footer {
      width: 100%;
      height: 112px;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      box-sizing: border-box;
      background: linear-gradient(90deg, rgba(231,44,30,1) 0%, rgba(244,77,35,1) 52%, rgba(231,44,30,1) 100%);
      border-radius: 0 0 16px 16px;
      .f-card-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 114px;
        flex: 1;
        height: 72px;
        background: linear-gradient(180deg, rgba(254,249,226,1) 0%, rgba(255,193,72,1) 100%);
        box-shadow: 0 8px 8px 0 rgba(214,25,0,1);
        font-size: 28px;
        color: #EB0E00;
        img {
          width: 32px;
          height: 32px;
          margin-left: 20px;
        }
      }
      .f-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #EBDBC1;
        width: 194px;
        height: 72px;
        border-radius: 114px;
        font-size: 28px;
        box-sizing: border-box;
        margin-right: 16px;
        color: #EBDBC1;
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
      }
    }
  }

</style>