<template>
  <div class="container">
    <div class="appbar" v-if="appbar">
      <van-nav-bar
        :style="{ background: background }"
        :left-text="textLeft"
        :right-text="textRight"
      >
      </van-nav-bar>
    </div>
    <div class="content">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ShortMessage",
  props: {
    appbar: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: ""
    },
    textLeft: {
      type: String,
      default: ""
    },
    textRight: {
      type: String,
      default: ""
    }
  }
});
</script>
<style lang="scss">
@import "~@/assets/style/mixin.scss";
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    box-shadow: 1px 2px 3px #eeeeee;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  .content {
    flex: 1;
    overflow: auto;
    height: calc(100vh - 46px);
  }
}
.van-nav-bar__text {
  color: #ffffff;
}
.van-nav-bar__right {
  padding: 0 10px;
  right: 20px !important;
}
</style>
