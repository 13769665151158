<template>
  <div class="cont">
    <div v-for="(item, index) in info" :key="index">
      <div class="cont_row" v-if="item.mobile">
        <div class="cont_row_left">
          <div>电话：</div>
          <div>{{ item.mobile }}</div>
          <img alt="logo" src="../../assets/image/afPlatfrom/copy.png" @click="copyText(item.mobile)" />
        </div>
        <div class="cont_row_right">
          <span @click="makeCall(item.mobile)">拨打</span>
        </div>
      </div>
      <div class="cont_row" v-if="item.wechat || item.wechatQRCode">
        <div class="cont_row_left" style="justify-content: flex-start;">
          <div>微信：</div>
          <div v-if="showWechatContacts(item.wechat)" style="font-size: 15px;color: #3061fd;" @click="navWachatContacts(item.wechat)">点击前往微信客服</div>
          <div v-else  class="cont_row_left_wechat">
            <div class="textHidden">{{ item.wechat }}</div>
            <img alt="logo" src="../../assets/image/afPlatfrom/copy.png" v-if="item.wechat"
              @click="copyText(item.wechat)" />
          </div>
        </div>
        <div class="cont_row_right">
          <span @click="previewCode(item.wechatQRCode, 'wechatQRCode')" v-if="item.wechatQRCode">查看二维码</span>
        </div>
      </div>
      <div class="cont_row" v-if="item.qq || item.qqQRCode">
        <div class="cont_row_left">
          <div>QQ：</div>
          <div>{{ item.qq }}</div>
          <img alt="logo" src="../../assets/image/afPlatfrom/copy.png" v-if="item.qq" @click="copyText(item.wechat)" />
        </div>
        <div class="cont_row_right">
          <span @click="previewCode(item.qqQRCode, 'qqQRCode')" v-if="item.qqQRCode">查看二维码</span>
        </div>
      </div>
    </div>
    <div class="cont_close" @click="close()">
      取消
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, toRefs, defineComponent, nextTick } from 'vue'

export default defineComponent({
  name: 'Contacts', props: {
    info: {
      type: Object,
      default: {}
    }
  },
  setup(prop, context) {
    const state = reactive({
      previewCodeShow: false,
    })
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx;

    const methods = {
      close() {
        ctx.$emit("close")
      },
      makeCall(tel) {
        const a = document.createElement("a");
        a.href = `tel:${tel}`
        a.click();
      },
      showWechatContacts(url){
        if(!url) return false
        if(url.indexOf('http') >= 0){
          return true
        }
        return false
      },
      navWachatContacts(url){
        window.location.href = url
      },
      copyText(text) {
        var input = document.createElement("input"); // 创建input对象
        input.value = text; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        tool.toast({
          msg: '复制成功！',
        });
      },
      previewCode(codeImg, type) {
        ctx.$emit("previewCodeShow", {
          codeImg,
          type
        })
      },
      init(obj, couponObj = '') {
      },
    }

    return {
      ...toRefs(state),
      ...methods,
    }
  }
})
</script>

<style lang="scss" scoped>
.cont {
  // width: calc(100% - 96px);
  margin: auto;

  .title {
    line-height: 100px;
    text-align: center;
    font-weight: 700;
  }

  .cont_row {
    display: flex;
    justify-content: space-between;
    height: 112px;
    align-items: center;
    border-bottom: 0.5px solid rgba(243, 243, 243, 1);
    color: rgba(0, 0, 0, 0.8);
    font-size: 32px;
    // font-weight: 700;
    padding: 0px 48px 0px 48px;

    .cont_row_left {
      width: 69%;
      display: flex;
      align-items: center;
      .cont_row_left_wechat{
      display: flex;
      justify-content: space-between;
      align-items: center;
      }
      img {
        width: 32px;
        height: 32px;
        margin-left: 16px;
      }

    }

    .cont_row_right {
      // width: 45%;
      display: flex;
      color: #3061fd;
      font-size: 36px;
      background-image: -webkit-linear-gradient(left, #3c98fe, #3061fd);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }

  }

  .cont_close {
    color: rgba(0, 0, 0, 0.8);
    font-size: 32px;
    height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 16px rgba(243,243,243,1);
  }
}
.textHidden{
  overflow: hidden;
 text-overflow:ellipsis;
 white-space: nowrap;
}
</style>