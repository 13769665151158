<template>
  <div class="inputHistory">
    <div
      :key="index"
      v-show="iccid == '' || item.indexOf(iccid) > -1"
      v-for="(item, index) in dataList"
    >
      <span @click="submit(item)">{{ item }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, toRefs } from "vue";
export default defineComponent({
  name: "InputHistory",
  props: {
    dataList: {
      type: Array,
      default: [],
    },
    iccid: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const state = reactive({});
    const { proxy:ctx } = getCurrentInstance();
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    const methods = {
      submit(item) {
        context.emit("setData", item);
      },
    };
    return { ...toRefs(state), ...methods };
  },
});
</script>
<style lang="scss" scoped>
.inputHistory {
  padding: 10px 10px;
  z-index: 9999;
}
.inputHistory div {
  height: 50px;
  line-height: 50px;
  background: #f0f0f06b;
  border-radius: 10px;
  margin: 20px 0;
  padding: 10px;
  box-sizing: content-box;
  cursor: pointer;
}
.inputHistory div span {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: inline-block;
}
</style>
