<template>
  <van-action-sheet
    v-model:show="show"
    title="自动续费服务协议"
    style="background: #f1f4f9"
  >
    <div class="content">
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您开通自动续费功能，本协议中的自动续费功能是由中创（深圳）物联网有限公司（以下简称“我司”）依据本协议为用户（以下简称 “您”）提供的物联网产品流量套餐充值服务，本协议对您及我司均具有法律效力。
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您确认开通自动续费功能时，则视为您已阅读并同意接受本协议的约束。如果您对本协议中任何条款表示异议的，应自行关闭自动续费功能。
      </section>
      <p class="title">第一条 服务协议的接受和修订</p>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1本协议内容包括协议正文以及我司已经发布的或将来可能发布/更新的各类规则。所有规则均为本协议不可分割的组成部分，与协议正文具有同等法律效力，本协议正文与前述规则存在冲突的，以本协议正文为准。
      </section>
      <p class="title">第二条 服务说明</p>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1
        2.1 会员权益：插卡即用，安装更方便，自动续费，免除设备离线烦恼。
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2
        2.2 当您开通自动续费功能时，即视为授权我司可在有效期即将过期前，从您在微信支付的账户或在微信支付关联的其他账户扣划下一个计费周期的费用。计费周期包括但不限于月度、季度、年度等（具体以实际充值平台/页面显示为准），我司将根据您选择的续费套餐类型进行相应费用扣除。
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3
        2.3 本服务实现的前提是我司成功扣除相应费用后，将款项记入支付记录，并相应延长物联网产品套餐的有效期，未支付则签约失效。
      </section>
      <p class="title">第三条 自动续费规则</p>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1
        3.1 您知悉并确认，购买并签约付款后，自动续费服务生效，我们会在服务到期前24小时内扣费，扣费成功后服务顺延一个周期。
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.2
        3.2 取消自动续费:如需取消续订，请在当前服务周期到期前24小时以前，进入微信，点击右下角>[我]>[服务]>[钱包] >[支付设置]>[自动续费]，在已签约项目中，点击取消。
      </section>
      <p class="title">第四条 服务有效期及终止</p>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本协议自用户确认开通之日起生效，当出现以下任一情形时自动终止，不视为违约：
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）用户自行关闭自动续费功能；
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）因用户余额不足未能成功扣款时；
      </section>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（3）因我司业务调整或其他法定/约定终止情况的，自公示之日起终止。
      </section>
      <p class="title">第五条 其他</p>
      <section>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您对本协议或开通自动续费功能服务相关事宜有任何问题的，可通过功能页面的在线客服进行反馈，我司将在收到您提出的问题或意见、建议后尽快回复您。
      </section>
    </div>
  </van-action-sheet>
</template>
  
<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
export default defineComponent({
  name: 'automaticRenewal',
  setup() {
    const state = reactive({
      show: false,
      format: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
    })
    const { proxy: ctx } = getCurrentInstance()
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx
    const methods = {}
    onMounted(() => {})
    return { ...toRefs(state), ...methods }
  },
})
</script>
<style lang="scss" scoped>
.content {
  padding: 16px 48px 160px;
  .title {
    font-size: 36px;
    font-weight: bold;
    margin: 24px 0;
  }
}
</style>

  