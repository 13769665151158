<template>
  <div class="container">
    <div class="appbar" v-if="appbar">
      <van-nav-bar
        :style="{ background: background, color: color }"
        left-arrow
        :title="title"
        :left-text="textLeft"
        :right-text="textRight"
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template v-if="showIcon" #right>
          <slot name="icon">
            <van-icon name="plus" size="18" @click="addNewCard" />
          </slot>
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "CardContainer",
  props: {
    appbar: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    background: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#fff"
    },
    textLeft: {
      type: String,
      default: ""
    },
    textRight: {
      type: String,
      default: ""
    },
    back: {
      type: String,
      default: ""
    }
  },
  setup(prop, ctx) {
    const router = useRouter();
    const methods = {
      onClickLeft() {
        router.back();
        ctx.emit("click-left");
      },
      onClickRight() {
        ctx.emit("click-right");
      },
      addNewCard() {
        router.push({
          name: "personal-addNewCard",
          query: {
            pageType: 3
          }
        });
      }
    };
    return {
      ...methods
    };
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";

.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    box-shadow: 1px 2px 3px #eeeeee;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  .content {
    flex: 1;
    overflow: auto;
  }
}
.van-nav-bar__right {
  padding: 0 30px !important;
}
</style>
