<template>
    <section class="actionSheetBox">
        <van-action-sheet v-model:show="show" :title="couponObject.goodsName" @cancel="onCancel">
            <div class="couponSwipe">
                <van-swipe class="my-swipe" loop="false" @change="onChange">
                    <van-swipe-item v-for="(item, index) in couponObject.couponList" :key="index">
                        <div class="top">
                            <div class="text_Title">{{ item.fullUse == 0 ? '无门槛' : '满' + item.fullUse + '元可用' }}</div>
                            <div class="operate">
                                <div>
                                    <img src="@/assets/image/popup/subtract_left.png" alt="">
                                </div>
                                <div class="textBox">
                                    <span class="pre_text">{{ item.couponAmount }}</span>
                                    <span class="after_text">元</span>
                                    <span class="icon">x</span>
                                    <span class="pre_text">{{ item.quantity }}</span>
                                    <span class="after_text">张</span>
                                </div>
                                <div>
                                    <img src="@/assets/image/popup/subtract_right.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <div style="color: rgba(0, 0, 0, 0.4);">绑定卡号</div>
                            <div>{{ userInfo.virtualId || userInfo.iccid }}</div>
                        </div>
                    </van-swipe-item>
                    <template #indicator="{ active, total }">
                        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
                    </template>
                </van-swipe>
            </div>
            <div class="usage_steps">
                <div class="steps">使用步骤:</div>
                <div class="imgBox">
                    <img src="@/assets/image/popup/coupon_steps.png" alt="">
                </div>
            </div>
            <div class="activity_steps" v-if="couponObject.goodsDesc">
                <div class="steps">活动须知:</div>
                <div class="tips">
                    {{ couponObject.goodsDesc }}
                </div>
            </div>
            <div class="recharge">
                <van-button type="primary" size="small" block @click="rechargeHandle()">
                    <span style="padding-right: 2px;">
                        ￥
                    </span>
                    <span>
                        {{ couponObject.salePrice }}抢购{{ couponObject.totalAmount }}元券
                    </span>
                </van-button>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model:show="show2" title="付款" @cancel="onCancel2">
            <div class="payAction">
                <van-radio-group v-model="payType">
                    <van-cell-group :border="false">
                        <van-cell v-if="['wx', 'other'].includes(userInfo.device)" center :border="false" title="微信支付"
                            clickable @click="payType = '1'">
                            <template #icon>
                                <img src="@/assets/image/popup/coupon_wechat.png" alt="" />
                            </template>
                            <template #right-icon>
                                <van-radio name="1" :checked-color="tool.getThemeList(theme)" />
                            </template>
                        </van-cell>
                        <van-cell v-if="['ali', 'other'].includes(userInfo.device)" class="cellMargin" center
                            :border="false" title="支付宝支付" clickable @click="payType = '2'">
                            <template #icon>
                                <img src="@/assets/image/popup/coupon_alipay.png" alt="" />
                            </template>
                            <template #right-icon>
                                <van-radio name="2" :checked-color="tool.getThemeList(theme)" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <div class="recharge">
                    <van-button type="primary" size="small" block @click="payHandle()">
                        <span style="padding-right: 2px;">
                            确认支付￥
                        </span>
                        <span>
                            {{ Number(couponObject.salePrice).toFixed(2) }}
                        </span>
                    </van-button>
                </div>
            </div>
        </van-action-sheet>
    </section>
</template>

<script>
import {
    defineComponent,
    toRefs,
    reactive,
    onMounted,
    getCurrentInstance,
} from "vue";
import { useRoute } from "vue-router";
import { setStore, getStore, removeStore } from "@/utils/store";

export default defineComponent({
    name: "CouponContainer",
    props: {
        couponObject: {
            type: Object,
            default: {}
        }
    },
    setup (prop, context) {
        const state = reactive({
            theme: "", //主题名称
            platformCode: '', //平台code码                        
            show: false, //优惠券面板展示
            show2: false, // 付款面板展示
            userInfo: {}, //用户信息
            userOpenId: {}, //用户的OpenId
            payInfo: { money: "", orderType: "", activeType: 0 }, //支付信息
            payType: "" // 用户选择的支付方式
        });
        // 这里的ctx  类似于vue2的this
        const { proxy: ctx } = getCurrentInstance();
        // 路由 this.$router
        const { $tool: tool, $store: store, $router: router, $http: http } = ctx;
        //调用useRoute,获取页面携带的参数
        const route = useRoute();

        const methods = {
            rechargeHandle () {
                state.show = false
                state.show2 = true
            },
            onCancel () {

            },
            onCancel2 () {
                state.show = true
                state.show2 = false
            },
            payHandle () {
                console.log('点击支付');
                if (!Boolean(state.payType)) {
                    tool.toast(
                        {
                            msg: "请先选择支付方式",
                            duration: 1500
                        },
                        () => { }
                    );
                }
                if (state.payType == "1") {
                    ctx.getWxPayInfo(2);
                }
                if (state.payType == "2") {
                    ctx.getAliPayInfo(2);
                }
            },
            //弹框展示
            isShow () {
                // 根据环境 展示默认支付方式
                state.payType = ''
                if (state.userInfo.device == 'wx') {
                    state.payType = '1'
                }
                if (state.userInfo.device == 'ali') {
                    state.payType = '2'
                }
                state.show = true;
            },
            //微信支付
            getWxPayInfo (type) {
                // state.userOpenId = getStore({ name: "userOpenId" });               
                let config = {
                    iccid: state.userInfo.iccid,
                    goodsId: ctx.couponObject.goodsId,
                    openid: state.userOpenId ? state.userOpenId.openId : null,
                    appId:state.userInfo.appid
                };
                switch (state.userInfo.device) {
                    case "wx":
                        config.tradeType = "JSAPI";
                        break;
                    case "other":
                        config.tradeType = "MWEB";
                        break;
                    default:
                        break;
                }

                return new Promise((resolve, reject) => {
                    http.post(`/pay/wxOrderCouponGoods`, config)
                        .then(async ({ data: res }) => {
                            if (res.code == 333 || res.code == 444) {
                                removeStore({ name: "userOpenId", type: "session" });
                                location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${state.userInfo.appid}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`;
                                return;
                            }
                            if (res.code == 0) {
                                switch (state.userInfo.device) {
                                    case "wx":
                                        console.log('res.data:', res.data);
                                        // state.payInfo = res.data;
                                        Object.assign(state.payInfo, res.data);
                                        ctx.wxPay();
                                        break;
                                    case "other":
                                        // 存储isCharge到sessionStorage
                                        setStore({
                                            name: "isCharge",
                                            content: "1",
                                            type: "session"
                                        });
                                        window.location.href = res.data.mweb_url;
                                        break;
                                    default:
                                        break;
                                }
                                return;
                            } else {
                                tool.alert(
                                    {
                                        type: "提示",
                                        msg: res.msg
                                    },
                                    () => { }
                                );
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            },
            //支付宝支付
            getAliPayInfo (type) {
                let config = {
                    iccid: state.userInfo.iccid,
                    goodsId: ctx.couponObject.goodsId,
                    openid: state.userOpenId ? state.userOpenId.openId : null,
                };
                switch (state.userInfo.device) {
                    case "ali":
                        config.tradeType = "0";
                        break;
                    case "other":
                        config.tradeType = "1";
                        break;
                    default:
                        break;
                }

                return new Promise((resolve, reject) => {
                    http.post(`/pay/aliOrderCouponGoods`, config)
                        .then(({ data: res }) => {
                            if (res.code == 0) {
                                ctx.aliPayFn(res.data.body, res, type, config);
                            } else {
                                tool.alert(
                                    {
                                        type: "提示",
                                        msg: res.msg
                                    },
                                    () => { }
                                );
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            },
            //JSAPI调起微信支付
            wxPay () {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener(
                            "WeixinJSBridgeReady",
                            ctx.onBridgeReady,
                            false
                        );
                    } else if (document.attachEvent) {
                        document.attachEvent("WeixinJSBridgeReady", ctx.onBridgeReady);
                        document.attachEvent("onWeixinJSBridgeReady", ctx.onBridgeReady);
                    }
                } else {
                    ctx.onBridgeReady();
                }
            },
            //JSAPI调起微信支付的事件函数
            onBridgeReady () {
                WeixinJSBridge.invoke(
                    "getBrandWCPayRequest",
                    {
                        appId: state.payInfo.appId,
                        timeStamp: state.payInfo.timeStamp,
                        nonceStr: state.payInfo.nonceStr,
                        package: state.payInfo.package,
                        signType: state.payInfo.signType,
                        paySign: state.payInfo.paySign
                    },
                    function (res) {
                        switch (res.err_msg) {
                            case "get_brand_wcpay_request:ok":
                                tool.toast(
                                    {
                                        msg: "支付成功，可在我的券页查看优惠券",
                                        duration: 1500
                                    },
                                    () => { state.show2 = false }
                                );
                                context.emit("onChang");
                                break;
                            case "get_brand_wcpay_request:cancel":
                                state.show = false;
                                tool.toast(
                                    {
                                        msg: "取消充值",
                                        duration: 1500
                                    },
                                    () => { }
                                );
                                break;
                            case "get_brand_wcpay_request:fail":
                                tool.toast(
                                    {
                                        msg: "充值失败",
                                        duration: 1500
                                    },
                                    () => { }
                                );
                                break;
                            default:
                                break;
                        }
                    }
                );
            },
            //alipayjsapi调起支付宝支付函数
            async aliPayFn (params, res, type, config) {
                switch (state.userInfo.device) {
                    case "ali":
                        ap.tradePay(
                            {
                                orderStr: params
                            },
                            res => {
                                let code = Number(res.resultCode);
                                switch (code) {
                                    case 9000:
                                        tool.toast(
                                            {
                                                msg: "支付成功，可在我的券页查看优惠券",
                                                duration: 1500
                                            },
                                            () => { state.show2 = false }
                                        );
                                        context.emit("onChang");
                                        break;
                                    case 800:
                                        tool.toast(
                                            {
                                                msg: "正在处理中，请等待系统审核",
                                                duration: 1500
                                            },
                                            () => {
                                                router.push({
                                                    name: "personal"
                                                });
                                            }
                                        );
                                        break;
                                    default:
                                        tool.toast(
                                            {
                                                msg: "充值失败",
                                                duration: 1500
                                            },
                                            () => { }
                                        );
                                        break;
                                }
                            }
                        );
                        break;
                    case "other":
                        // 存储isCharge到sessionStorage
                        setStore({
                            name: "isCharge",
                            content: "1",
                            type: "session"
                        });
                        let body = document.querySelector("body"); // 原有支付
                        let dom = new DOMParser().parseFromString(params, "text/html");
                        body.appendChild(dom.forms[0]);
                        document.forms[0].submit();
                        break;
                    default:
                        break;
                }
            },
            judgeOpenId () {
                if (
                    (state.userOpenId === undefined || state.userOpenId.openId === "0" || state.userInfo.isPackage) &&
                    state.userInfo.device === "wx"
                ) {
                    setStore({
                        name: "router",
                        content:
                            route.query.type == 0
                                ? "personal-packageRecharge"
                                : "stop-recharge",
                        type: "session"
                    });
                    location.replace(
                        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${state.userInfo.appid}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
                    );
                }
            },
            judgeAliOpenId () {
                if (
                    (state.userOpenId === undefined || state.userOpenId.openId === "0") &&
                    state.userInfo.device === "ali" && state.userInfo.aliAppId
                ) {
                    setStore({
                        name: "router",
                        content:
                            route.query.type == 0
                                ? "personal-packageRecharge"
                                : "stop-recharge",
                        type: "session"
                    });
                    location.replace(
                        `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${state.userInfo.aliAppId}&scope=auth_base&redirect_uri=${window.location.origin}/transfer-page`
                    );
                }
            }
        };
        onMounted(() => {
            store.dispatch("SetUserInfo", { isConfigAppid: false })
            //获取主题名称
            state.theme = store.state.common.theme;
            // 获取平台code 码
            state.platformCode = store.state.common.platformCode;
            //获取用户信息
            state. userInfo = getStore({ name: "userInfo" })
            //使用sessionStorage获取存储的openId信息
            state.userOpenId = getStore({ name: "userOpenId" });
            if (!['AnFang', 'YuYin'].includes(state.theme)) { 
                //判断是否有openId
                methods.judgeOpenId();
                //判断是否有AliopenId
                methods.judgeAliOpenId();
            }
            //h5支付结束
            let isCharge =
                getStore({
                    name: "isCharge"
                }) || "2";
            if (isCharge === "1") {
                tool.confirm(
                    {
                        title: "提示",
                        msg: "充值结果系统处理中"
                    },
                    () => {
                        if (state.theme == 'AnFang') return
                        window.history.back();
                    }
                );
                removeStore({ name: "isCharge", type: "session" });
            }
        });
        return {
            ...toRefs(state),
            ...methods,
            tool,
            store
        };
    }
});
</script>
<style lang="scss" scoped>
@import "@/style/theme/index";

.actionSheetBox {
    /deep/ .van-action-sheet__header {
        text-align: left;
        padding-left: 40px;
        font-weight: bold;
    }
}

.couponSwipe {
    padding: 0 32px;
    margin-bottom: 40px;

    /deep/ .van-swipe__indicators {
        top: 20px;
    }

    .my-swipe .van-swipe-item {
        width: 686px;
        height: 258px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        opacity: 1;
        border-radius: 16px;
        background: url("~_ASSETS_/image/popup/coupon_back.png") center/cover no-repeat;

        .top {
            display: flex;
            flex-direction: column;
            padding-left: 32px;

            .text_Title {
                height: 84px;
                line-height: 84px;

                font: {
                    size: 24px;
                    weight: 600;
                }
            }


            .operate {
                display: flex;
                height: 84px;
                line-height: 84px;

                img {
                    width: 16px;
                    height: 84px;
                }

                .textBox {
                    height: 84px;
                    background: linear-gradient(139deg, #ff974bff 0%, #fe3e14ff 100%);

                    .icon {
                        padding: 0 8px;
                        color: #fff;
                        position: relative;
                        bottom: 8px;
                    }

                    .pre_text {
                        color: #fff;

                        font: {
                            size: 48px;
                            weight: bold;
                        }
                    }

                    .after_text {
                        color: #fff;

                        font: {
                            size: 24px;
                        }
                    }
                }
            }
        }

        .bottom {
            display: flex;
            width: 82vw;
            height: 7.6vw;
            padding: 0 4.267vw;
            border-top: 1px dashed #E6E6E6;
            justify-content: space-between;
            align-items: center;
        }
    }

}

.usage_steps,
.activity_steps {
    width: 682px;
    border-radius: 16px;
    opacity: 1;
    margin: 0 32px;
    border: 1px dashed #E6E6E6;
}

.usage_steps {
    height: 28vw;

    .steps {
        font-size: 3.2vw;
        font-weight: 600;
        padding: 24px 0 24px 32px;
    }

    .imgBox {
        text-align: center;

        img {
            width: 622px;
            height: 100px;
        }
    }
}

.activity_steps {
    margin-top: 40px;

    .steps {
        font-size: 3.2vw;
        font-weight: 600;
        padding: 24px 0 24px 32px;
    }

    .tips {
        padding: 0 32px 16px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.6);

        div {
            padding-bottom: 8px;
        }
    }
}

.recharge {
    .van-button--primary {
        font-size: 32px;
        width: 92vw;
        height: 100px;
        border-radius: 16px;
        border: none;
        opacity: 1;
        margin: 20px auto;
        background: linear-gradient(139deg, #ff974bff 0%, #fe3e14ff 100%);
    }
}

.payAction {
    width: 94%;
    padding: 20px 0;
    margin: auto;


    .payType {
        font-size: 24px;
        padding: 12px;
    }

    .cellMargin {
        border-top: 1px solid #ececec;
    }

    /deep/ .van-cell {
        padding: 20px;

        .van-cell__title {
            color: #222;
        }

        img {
            width: 48px;
            margin-right: 20px;
        }

        .goRecharge {
            padding: 3px 15px;
            border-radius: 1.5em;
            color: #fff;
            @include background_color("background_color1");
        }

        .moneyPay {
            display: flex;
            align-items: center;

            .money {
                margin-left: 10px;
                font-size: 24px;
                @include font_color("font_color1");
            }
        }
    }
}

.custom-indicator {
    position: absolute;
    right: 2px;
    top: 0;
    // bottom: 225px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
}
</style>
