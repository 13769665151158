// 设备检测
export function isDevice() {
  if (/MicroMessenger/.test(window.navigator.userAgent)) {
    return "wx";
  } else if (/AlipayClient/.test(window.navigator.userAgent)) {
    return "ali";
  } else {
    return "other";
  }
}

//上传的图片转换为base64
export function fileToImgFn(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file); // 读出 base64
    let dataURL;
    reader.onloadend = () => {
      // 图片的 base64 格式, 可以直接当成 img 的 src 属性值
      dataURL = reader.result;
      resolve(dataURL);
    };
  });
}

export function urlAddParams (link,paramsStr){
  let key = link.indexOf('?')>-1?'&':'?'
  return link + key + paramsStr
}

// 清除对象元素内的值，条件自定义
export function emptyObject(obj,callback) {
	return Object.keys(obj).forEach((key) =>{callback(obj,key)});
}

// 对象转formData
export function paramsToFormData(obj) {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (obj[key] instanceof Array) {
      obj[key].forEach((item) => {
        formData.append(key, item);
      });
      return;
    }
    formData.append(key, obj[key]);
  });
  return formData;
}

export function daysUntil(dateString) {
    // 将给定字符串转换为Date对象
    var givenDate = new Date(dateString);

    // 获取当前日期对象
    var currentDate = new Date();

    // 计算两个日期的相差毫秒数
    var differenceInMilliseconds = Math.abs(givenDate - currentDate);

    // 转换为天数（向下取整）
    var differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays;
}
