<template>
  <div class="content_box">
    <div class="appbar"></div>
    <div class="content">
      <slot>
        <div class="title">修改白名单</div>
        <div class="card_num">
          <span class="desc">昵称:</span>
          <input
            type="text"
            style="border: none"
            :placeholder="name"
            v-model="afterUpdate.nickname"
          />
        </div>
        <div class="card_mobile_underline"></div>
        <div class="real_name" v-if="status == 0">
          <span class="desc">电话号码:</span>
          <input
            class="real_name_input"
            type="text"
            style="border: none"
            :placeholder="mobile"
            v-model="afterUpdate.telNum"
            maxlength="20"
          />
        </div>
      </slot>
      <slot>
        <div class="choice">
          <div class="cancel" @click="close">取消</div>
          <div class="submit" @click="submit">确定</div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
export default defineComponent({
  name: "ListModify",
  props: {
    name: {
      type: String,
      default: ""
    },
    mobile: {
      type: String,
      default: ""
    },
    status: {
      type: Number,
      default: 0
    }
  },
  setup(props, context) {
    const state = reactive({
      card: "", //卡号
      realName: "",
      afterUpdate: {
        show_notice: false, //弹框展示
        nickname: "", //修改后的昵称
        telNum: "", //修改后的手机号码
        submit_show: false //确认是否修改的弹框控制展示
      } //昵称更新后
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $store: store } = ctx;
    const methods = {
      close() {
        //通过emit传递昵称更新后的事件和对应的值
        context.emit("show", state.afterUpdate);
        state.afterUpdate["nickname"] = "";
      },
      submit() {
        //通过emit传递昵称更新后的事件和对应的值
        context.emit("show", state.afterUpdate);
      }
    };
    onMounted(() => {
      //获取用户信息
      const info = store.state.user.userInfo;
      state.card = info.iccid;
    });
    return { ...toRefs(state), ...methods };
  }
});
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixin.scss";

.content_box {
  display: flex;
  flex-direction: column;
  width: 615px;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .content {
    flex: 1;
    overflow: auto;
    .title {
      text-align: center;
    }
    .card_num,
    .real_name {
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .real_name_input {
        width: 430px;
      }
    }
    .card_mobile_underline {
      width: 100%;
      height: 1px;
      background: #f2f2f2;
    }
    .card_num {
      width: 600px;
      justify-content: space-around;
      input {
        width: 430px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .real_name {
      width: 576px;
      margin-left: 36px;
      input {
        width: 430px;
        margin-left: 16px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .choice {
      height: 88px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 88px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }
      .submit {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #2196f3;
      }
    }
  }
}
</style>
