<template>
  <div class="confirmOrder">

    <slot>
      <van-action-sheet v-model:show="show" :title="title" :round="true" :closeable="false"
        :style="{ background: '#F7F7F7' }">
        <div class="content">
          <div class="tips" v-if="actionType == 2">
            说明:充值余额以后，可以使用余额购买套餐，购买套餐以后卡片才可以使用。
          </div>
          <div class="packageInfo">
            <template v-if="actionType == 1">
              <div class="item">
                <div class="left title" style="">充值卡号</div>
                <div class="right desc">
                  {{ store.state.user.userInfo.virtualId || store.state.user.userInfo.iccid }}
                </div>
              </div>
              <div class="item" :class="{packageItem:setMealObject.packageName.length > 20}">
                <div class="left title">套餐名称</div>
                <div class="right desc">
                  {{ setMealObject.packageName }}
                </div>
              </div>
              <div class="item" v-if="(setMealObject.packageType == 4 && payInfo.orderType == 'ordinaryOrder' && flowStatus != 'used') || (setMealObject.packageType != 4 && payInfo.orderType == 'ordinaryOrder' && flowStatus != 'used' && !labels.includes(4))">
                <div class="left title">
                  {{
                      setMealObject.packageType == 4 ? "短信条数" : "套餐流量"
                  }}
                </div>
                <div class="right desc" v-if="setMealObject.cycle <= 1">
                  {{ setMealObject.flow
                  }}{{ setMealObject.packageType == 4 ? "条" : "MB" }}/{{
    setMealObject.cycleCategory == 1 ? "天" : setMealObject.cycleCategory == 2 ? "月"
      : setMealObject.cycleCategory == 3 ? "月" : setMealObject.cycleCategory >= 4 ? "年 " : "天"
}}
                </div>
                <div class="right desc" v-else>
                  <template v-if="setMealObject.stagingFlow">
                    {{ setMealObject.stagingFlow
                    }}{{ setMealObject.packageType == 4 ? "条" : "MB" }}/{{
    setMealObject.cycleCategory == 1 ? "天" : setMealObject.cycleCategory == 2 ? "月"
      : setMealObject.cycleCategory == 3 ? "月" : setMealObject.cycleCategory >= 4 ? "年 " : "天"
}}&emsp;
                  </template>
                  <span v-if="!isShowPackageInfoTips">
                    共{{ setMealObject.flow
                    }}{{ setMealObject.packageType == 4 ? "条" : "MB" }}/{{
    setMealObject.cycle
}}{{ setMealObject.cycleCategory == 1 ? "天" : setMealObject.cycleCategory == 2 ? "月"
    : setMealObject.cycleCategory == 3 ? "月" : setMealObject.cycleCategory >= 4 ? "年 " : "天"
}}
                  </span>
                </div>
              </div>

              <div class="item" v-if="
                setMealObject.packageType === 3 &&
                payInfo.orderType == 'ordinaryOrder' && !labels.includes(4)
              ">
                <div class="left title">套餐语音</div>
                <div class="right desc">
                  <u class="packageVoice" v-if="setMealObject.stagingVoice !== null">
                    {{ setMealObject.stagingVoice }}分钟/月
                  </u>
                  <span v-if="!isShowPackageInfoTips">
                    共{{ setMealObject.voice }}分钟/{{ setMealObject.cycle }} 月
                  </span>
                </div>
              </div>

              <!-- <div class="item" v-if="!isShowPackageInfoTips">
                <div class="left title">生效时间:</div>
                <div class="right desc colorTetx">
                  {{ effect || setMealObject.effect }}
                </div>
              </div> -->
              <div class="item">
                <div class="left title">套餐金额</div>
                <div class="right desc colorTetx_">¥{{ chargeNum }}</div>
              </div>
              <div class="item" v-if="!stopPackage">
                <div class="left title">优惠券:</div>
                <div class="right decs " @click="openCoupon(setMealObject)">
                  <span v-if="couponObj.couponDistributionRecordId || setMealObject.couponDistributionRecordId">
                    <span class="colorTetx">-{{couponObj.discountAmount || 0}}</span>
                    <van-icon name="arrow" />
                  </span>
                  <span v-else style="color: rgba(0,0,0,0.4);">无可用券</span>
                </div>
              </div>
              <div class="item">
                <div class="left title">套餐说明</div>
                <div class="right desc_remark">
                  {{ setMealObject.remarks }}
                </div>
              </div>
              <div class="item" v-if="Boolean(buyMultiplePackages)">
                <div class="left title">购买数量</div>
                <van-stepper v-model="num" max='12' :disabled="payInfo.orderType == 'stopOrder'"
                  @change="changeHandle" />
              </div>
              <!-- <div class="item acttips" v-if="Boolean(buyMultiplePackages)">
                <img src="../../assets/image/yyPlatfrom/tips.png" alt="" />
                点加号试试，有惊喜！
              </div> -->
            </template>
            <div class="item" v-else>
              <div class="left title">充值金额</div>
              <div class="right desc redColorTetx">￥{{ chargeNum }}</div>
            </div>
          </div>
          <div class="pay-methods">
            <!-- <p class="payType">支付方式:</p> -->
            <van-radio-group v-model="payType">
              <van-cell-group :border="false" inset style="margin: 0;">
                <!-- <van-cell
                  center
                  :border="false"
                  clickable
                  @click="setPayType('0', true)"
                  v-if="actionType == 1 && (sysPayType == 4 || sysPayType == 0)"
                >
                  <template #icon>
                    <img src="../../assets/image/popup/order_icon.png" alt="" />
                  </template>
                  <template #title>
                    <div class="pay-method-label">
                      <span>余额支付</span>
                      <div class="money">
                        用户/卡余额：{{ userMoney }}/{{ cardMoney }}
                      </div>
                    </div>
                  </template>
                  <template #right-icon>
                    <template
                      v-if="userMoney >= chargeNum || cardMoney >= chargeNum"
                    >
                      <van-radio
                        name="0"
                        :checked-color="tool.getThemeList(theme)"
                      />
                    </template>
                    <template v-else>
                      <span class="goRecharge" @click="goChargeMoney"
                        >去充值</span
                      >
                    </template>
                  </template>
                </van-cell> -->
                <van-cell v-if="
                  (userInfo.device == 'wx' || userInfo.device == 'other') &&
                  ((actionType == 2 && sysPayType == 0) ||
                    actionType == 2 ||
                    sysPayType == 1 ||
                    sysPayType == 4 || (sysPayType == 0 && actionType == 1 && num >= 2)
                  )
                " center :border="false" title="微信支付" clickable @click="setPayType('1', paySelection.wx)">
                  <template #title>
                    <div class="pay-method-label">
                      <img style="width: 24px;height: 24px" :src="require(`_ASSETS_/image/popup/new_wechat.png`)"
                        alt="">
                      <span>微信支付</span>
                    </div>
                  </template>
                  <template #right-icon>
                    <van-radio name="1" :disabled="!paySelection.wx" :checked-color="tool.getThemeList(theme)" />
                  </template>
                </van-cell>
                <van-cell v-if="
                  (userInfo.device == 'ali' || userInfo.device == 'other') &&
                  ((actionType == 2 && sysPayType == 0) ||
                    actionType == 2 ||
                    sysPayType == 3 ||
                    sysPayType == 4)
                " center :border="false" title="支付宝支付" clickable @click="setPayType('3', paySelection.ali)">
                  <template #title>
                    <div class="pay-method-label">
                      <img style="width: 24px;height: 24px" :src="require(`_ASSETS_/image/popup/new_alipay.png`)"
                        alt="">
                      <span>支付宝支付</span>
                    </div>
                  </template>
                  <template #right-icon>
                    <van-radio name="3" :disabled="!paySelection.ali" :checked-color="tool.getThemeList(theme)" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>

          <div class="chargeBtn_box">
            <van-button type="primary" block size="large" class="chargeBtn" @click="Pay" :disabled="disabledCharge">
              立即充值￥{{ couponMoney() || chargeMoney }}
            </van-button>
          </div>
        </div>
      </van-action-sheet>
    </slot>

    <!-- 优惠券弹框  -->
    <coupon-dialog ref="CouponDialog" @UpdataCoupon="UpdataCoupon"></coupon-dialog>

  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  onMounted,
  computed,
  getCurrentInstance,
  nextTick
} from 'vue'
import { useRoute } from 'vue-router'
import { setStore, getStore, removeStore } from '@/utils/store'
import { wxOrder, aliPayOrder, walletPayOrder, fetchWxSchema } from '_API_/api.services'

import nativeCallBack from '@/utils/nativeCallBack'
import { trackSubmit, handleToLowerCase } from '@/utils/track'

const { nativeIntercation } = nativeCallBack
export default defineComponent({
  name: 'ConfirmOrder',
  props: {
    title: {
      type: String,
      default: ''
    },
    buyMultiplePackages: {
      type: Number,
      default: 0
    },
    chargeNum: {
      type: Number,
      default: 0
    },
    setMealObject: {
      type: Object,
      default: {}
    },
    cardMoney: {
      type: Number,
      default: 0
    },
    userMoney: {
      type: Number,
      default: 0
    },
    stopPackage: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: []
    }
  },
  setup (prop, context) {
    const state = reactive({
      flag: false, // 注销确定按钮&&Loading
      zhongtian: false, // 安防中天app内嵌web需上报支付信息
      theme: '', // 主题名称
      platformCode: '', // 平台code码
      num: 1, // 套餐数量
      effect: '', // 生效日期
      actionType: 1, // 1 充值套餐 2 充值余额
      show: false, // 弹框展示
      userInfo: {}, // 用户信息
      userOpenId: {}, // 用户的OpenId
      payInfo: { money: '', orderType: '', activeType: 0 }, // 支付信息
      payType: '', // 用户选择的支付方式
      sysPayType: '', // 系统设置的支付方式
      paySelection: {
        wx: false,
        ali: false
      }, // 过滤支付选项
      flowStatus: 'all', // 展示流量： used : 不展示 /  all ： 展示
      isShowPackageInfoTips: false, // 语音平台屏蔽 【生效时间 & 套餐信息】
      activeIcon: 'https://fastly.jsdelivr.net/npm/@vant/assets/user-active.png',
      inactiveIcon: 'https://fastly.jsdelivr.net/npm/@vant/assets/user-inactive.png',
      couponObj: {}
    })
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance()
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router, $http: http } = ctx
    var u = navigator.userAgent
    state.zhongtian = u.indexOf('zhongtian') > -1
    // 调用useRoute,获取页面携带的参数
    const route = useRoute()
    // 计算属性=>disabledCharge
    const disabledCharge = computed(() => {
      if (state.actionType == 1 && state.sysPayType == 1 && state.userInfo.device == 'ali') {
        return true
      }
      if (state.actionType == 1 && state.sysPayType == 3 && state.userInfo.device == 'wx') {
        return true
      }
      return false
    })
    // 套餐充值金额
    const chargeMoney = computed(() => {
      const totalAmount = ctx.chargeNum * state.num
      // 小数点 2位
      let billableAmount = totalAmount.toFixed(2)
      // 取小数点后的数值
      const floor = billableAmount.split('.')[1]
      // 截取最后的位数
      const isZero = Number(floor.slice(1))
      if (!isZero) {
        const res = billableAmount.slice(0, billableAmount.length - 1)
        billableAmount = Number(res)
      } else {
        return Number(billableAmount)
      }
      return billableAmount
    })
    // 充值金额
    const methods = {
      // 优惠券计算金额
      couponMoney () {
        if (state.couponObj.couponDistributionRecordId) {
          const totalAmount = (Number(chargeMoney.value) - Number(state.couponObj.discountAmount)).toFixed(2)
          return totalAmount
        }
        return ''
      },
      // 优惠券弹框
      openCoupon (obj) {
        if (!obj.couponDistributionRecordId) return
        ctx.$nextTick(() => {
          ctx.$refs.CouponDialog.init(obj, state.couponObj.couponDistributionRecordId ? state.couponObj : '')
        })
      },
      UpdataCoupon (val) {
        console.log(JSON.stringify(val))
        state.couponObj = val || {}
      },
      /**
       * payParams: 支付参数 obj
       */
      fetchWxSchemaURL (payParams) {
        // 获取微信schema地址 （支付参数）
        fetchWxSchema(payParams).then(({ data }) => {
          if (data.code == 0) {
            return location.href = data.msg
          }
          console.log(data.msg)
        }).catch(err => {
          console.log(err)
        })
      },
      // 弹框展示
      isShow () {
        state.num = 1
        state.effect = ''
        state.show = true
        ctx.setPaySelection()
        if (ctx.setMealObject.couponDistributionRecordId) {
          state.couponObj = Object.assign({}, state.couponObj, {
            couponDistributionRecordId: ctx.setMealObject.couponDistributionRecordId,
            postCouponPrice: ctx.setMealObject.postCouponPrice,
            discountAmount: ctx.setMealObject.discountAmount
          })
          return
        }
        state.couponObj = {}
      },
      // 购买数量
      changeHandle (value) {
        state.num = value
        http.post('/card/queryPackageEffect', {
          card: store.state.user.userInfo.iccid,
          packagePrice: ctx.setMealObject,
          num: value
        }).then(({ data: res }) => {
          if (res.code == 0) {
            state.effect = res.effect
          }
        })
      },
      setPaySelection () {
        state.payType = ''
        state.sysPayType = store.state.user.userInfo.payType
        // 如果系统配置只有一种支付方式，则默认勾选该方式
        if (state.sysPayType !== 4) {
          state.payType = String(state.sysPayType)
        }
        // 余额充值  微信 支付宝环境下默认勾选
        if (state.sysPayType == 1 && state.userInfo.device == 'wx') {
          state.payType = '1'
        }
        if (state.sysPayType == 1 && state.userInfo.device == 'ali') {
          state.payType = '3'
        }
        if (state.sysPayType == 3 && state.userInfo.device == 'ali') {
          state.payType = '3'
        }
        if (state.sysPayType == 3 && state.userInfo.device == 'wx') {
          state.payType = '1'
        }
        // 系统支付为微信支付 或 支付宝支付 且在浏览器环境下进行余额充值 不勾选
        if (
          (state.sysPayType == 1 || state.sysPayType == 3) &&
          state.userInfo.device == 'other'
        ) {
          state.payType = ''
        }
        // 系统支付为微信支付 或 支付宝支付  且 是充值套餐 且在浏览器环境下 则默认勾选
        if (
          (state.sysPayType == 1 || state.sysPayType == 3) &&
          state.actionType == 1 &&
          state.userInfo.device == 'other'
        ) {
          state.payType = String(state.sysPayType)
        }
        // 系统支付为余额支付 或 微信支付 或 全部  且 是余额充值 且在微信环境下 则默认勾选
        if (
          (state.sysPayType == 0 ||
            state.sysPayType == 1 ||
            state.sysPayType == 4) &&
          state.actionType == 2 &&
          state.userInfo.device == 'wx'
        ) {
          state.payType = '1'
        }
        // 系统支付为余额支付 或 支付宝支付 或 全部  且 是余额充值 且在支付宝环境下 则默认勾选
        if (
          (state.sysPayType == 0 ||
            state.sysPayType == 3 ||
            state.sysPayType == 4) &&
          state.actionType == 2 &&
          state.userInfo.device == 'ali'
        ) {
          state.payType = '3'
        }
        const conf = {
          wx: true,
          ali: true
        }
        if (state.sysPayType == 0 && state.userInfo.device == 'wx') {
          conf.ali = false
        }
        if (
          (state.sysPayType == 1 || state.sysPayType == 4) &&
          state.userInfo.device == 'ali'
        ) {
          conf.wx = false
        }
        if (
          (state.sysPayType == 3 || state.sysPayType == 4) &&
          state.userInfo.device == 'wx'
        ) {
          conf.ali = false
        }
        state.paySelection = conf
      },
      /** 去充值
       * @param {type} active
       * 套餐充值的余额充值通过子传父并携带值
       * 停机保号的余额充值通过路由携带参数跳转
       */
      goChargeMoney () {
        switch (state.payInfo.orderType) {
          case 'ordinaryOrder':
            state.show = false
            context.emit('selectActive', {
              active: 1,
              typeActive: state.payInfo.activeType
            })
            break
          case 'stopOrder':
            router.push({
              name: 'personal-packageRecharge',
              query: {
                active: 1
              }
            })
            break
          default:
            break
        }
      },
      // 设置支付方式
      setPayType (type, action) {
        if (!action) return
        state.payType = type
      },
      // 支付
      Pay () {
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_confirm_click',
          payType: state.payType,
          cardBalance: ctx.cardMoney
        })
        // 若系统支付仅开放 余额支付 且 是订购套餐 且数量大于等于2 且 用户选择微信支付
        if (state.sysPayType == 0 && state.actionType == 1 && state.num >= 2 && state.payType == '1') {
          return ctx.getWxPayInfo(state.actionType == 1 ? 2 : 1)
        }
        if (
          (state.payType == '' && state.actionType == 1) ||
          (state.payType == '0' && state.actionType == 2)
        ) {
          return tool.toast({
            msg: '请选择支付方式',
            duration: 1500
          })
        }
        if (state.payType == '1') {
          ctx.getWxPayInfo(state.actionType == 1 ? 2 : 1)
        }
        if (state.payType == '3') {
          ctx.getAliPayInfo(state.actionType == 1 ? 2 : 1)
        }
      },
      // 微信支付
      getWxPayInfo (type) {
        state.userOpenId = getStore({ name: 'userOpenId' })
        /**
         * 参数说明
         * @param {*} type   1:微信充值余额   2:微信支付下单
         * @param {*} payType 1: 公众号支付  2: 小程序支付
         * @param {*} config   接口所需的参数
         */
        /**
         * 参数说明
         * @param {*} paymentWxType
         * 0-微信 1-通联 2-首赋 3-杉德支付
        */
        const paymentWxType = state.userInfo.paymentWxType
        console.log('paymentWxType:', paymentWxType)
        const config = {
          payType: 1,
          platformCode: state.platformCode,
          iccid: state.userInfo.iccid,
          trxamt: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          orderAmount: chargeMoney.value,
          couponDistributionRecordId: state.couponObj.couponDistributionRecordId || '',
          body: '微信测试充值',
          openid: state.userOpenId ? state.userOpenId.openId : null,
          // appId: state.userInfo.appid,
          appId: state.userInfo.wxPayAppid,
          num: state.num,
          url: paymentWxType == 1 ? window.location.origin + '/pay-result' : window.location.origin
        }
        switch (state.userInfo.device) {
          case 'wx':
            config.tradeType = 'JSAPI'
            break
          case 'other':
            config.tradeType = 'MWEB'
            break
          default:
            break
        }
        if (type == 2) {
          config.packageId = ctx.setMealObject.packageId
          config.trxamt = state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value
        }
        if (state.userInfo.device === 'other' && paymentWxType == 1) { // 走通联,小程序调起支付
          return methods.fetchWxSchemaURL(config)
        }
        if (state.userInfo.device === 'wx' && paymentWxType == 0) { // 走原生微信jsapi调起支付
          const tongLianInfo = {
            isTongLian: false
          }
          store.commit('Set_TongLian_Info', tongLianInfo)
        }
        if (state.userInfo.device === 'wx' && paymentWxType == 1) { // 走通联,微信jsapi调起支付
          const tongLianInfo = {
            isTongLian: true,
            card: state.userInfo.virtualId || state.userInfo.iccid
          }
          store.commit('Set_TongLian_Info', tongLianInfo)
        }
        // if (paymentWxType == 2) { // 走首赋
        //   return
        // }
        return new Promise((resolve, reject) => {
          // 微信支付=>余额充值
          wxOrder(config)
            .then(async ({ data: res }) => {
              console.log('wxpay', res)
              if (res.code == 333 || res.code == 444) {
                setStore({
                  name: 'router',
                  content: 'yy-platfrom',
                  type: 'session'
                })
                removeStore({ name: 'userOpenId', type: 'session' })
                location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${state.userInfo.appid}&redirect_uri=${window.location.origin}/transfer-page&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
                return
              }
              if (res.code == 0) {
                switch (state.userInfo.device) {
                  case 'wx':
                    // state.payInfo = res.data;
                    Object.assign(state.payInfo, res.data)
                    ctx.wxPay()
                    break
                  case 'other':
                    // 存储isCharge到sessionStorage
                    setStore({
                      name: 'isCharge',
                      content: '1',
                      type: 'session'
                    })
                    if (paymentWxType == 3) { // 走杉德
                      window.location.href = `${res.data.url}${tool.params(res.data)}` // 杉德
                    } else {
                      try {
                        // 安防中天嵌入web 提交支付参数给app
                        if (state.zhongtian && type == 2) {
                          await nativeIntercation('sendPayInfo', Object.assign(res.extendOrderParam, {
                            payType: state.payType,
                            amount: config.trxamt,
                            timeStamp: new Date().getTime()
                          }))
                        }
                      } catch (error) {
                        console.log('传递支付参数异常')
                      }
                      trackSubmit({
                        eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
                        payAmount: chargeMoney.value
                      })
                      window.location.href = res.data.mweb_url // 原有
                    }
                    break
                  default:
                    break
                }
              } else {
                tool.alert(
                  {
                    type: '提示',
                    msg: res.msg
                  },
                  () => { }
                )
              }
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      handlePayResult () {
        const obj = {
          card: state.userInfo.virtualId || state.userInfo.iccid,
          money: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          packageName: ctx.setMealObject.packageName || '',
          desc: `${ctx.setMealObject.openManualRenewal == 1 ? '流量到账可能会存在延迟，工作日时间09:00-17:00流量生效时间预计为6小时内，其他时间预计为次日到账。' : '系统存在一定的延迟，将会在30分钟左右到帐如有问题建议您先智能诊断或者联系客服处理'}`,
          actionType: state.actionType,
          orderType: state.payInfo.orderType,
          activeType: state.payInfo.activeType // 充值套餐选中类型
        }
        router.push({
          name: 'pay-result',
          query: obj
        })
      },
      // 支付宝支付
      getAliPayInfo (type) {
        /**
         * 参数说明
         * @param {*} type   1:支付宝充值余额   2:支付宝支付下单
         * @param {*} config   接口所需的参数
         */
        /**
         * 参数说明
         * @param {*} paymentAliType
         * 1-支付宝 2-通联 3-杉德支付
        */
        const paymentAliType = state.userInfo.paymentAliType
        console.log('paymentAliType:', paymentAliType)
        const config = {
          iccid: state.userInfo.iccid,
          trxamt: state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value,
          orderAmount: chargeMoney.value,
          couponDistributionRecordId: state.couponObj.couponDistributionRecordId || '',
          body: '支付宝测试充值',
          openid: state.userOpenId ? state.userOpenId.openId : null,
          appId: state.userInfo.aliAppId,
          url: window.location.origin,
          num: state.num
        }
        switch (state.userInfo.device) {
          case 'ali':
            config.tradeType = '0'
            break
          case 'other':
            config.tradeType = '1'
            break
          default:
            break
        }
        if (type == 2) {
          config.packageId = ctx.setMealObject.packageId
          config.trxamt = state.couponObj.couponDistributionRecordId ? ctx.couponMoney() : chargeMoney.value
        }
        return new Promise((resolve, reject) => {
          // 支付宝支付=>余额充值
          aliPayOrder(config)
            .then(({ data: res }) => {
              if (res.code == 0) {
                if (state.userInfo.device === 'other' && paymentAliType == 2) { // 走通联
                  return location.href = res.data.payUrl
                }
                if (state.userInfo.device === 'ali' && paymentAliType == 2) { // 走通联
                  return ctx.aliPayTongLianFn(res.data)
                }
                if (paymentAliType == 3) { // 走杉德
                  return ctx.aliPaySDFn(res.data)
                }
                ctx.aliPayFn(res.data.body, res, type, config)
              } else {
                tool.alert(
                  {
                    type: '提示',
                    msg: res.msg
                  },
                  () => { }
                )
              }
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      // JSAPI调起微信支付
      wxPay () {
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
          payAmount: chargeMoney.value
        })
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener(
              'WeixinJSBridgeReady',
              ctx.onBridgeReady,
              false
            )
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', ctx.onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', ctx.onBridgeReady)
          }
        } else {
          ctx.onBridgeReady()
        }
      },
      // JSAPI调起微信支付的事件函数
      onBridgeReady () {
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: state.payInfo.appId,
            timeStamp: state.payInfo.timeStamp,
            nonceStr: state.payInfo.nonceStr,
            package: state.payInfo.package,
            signType: state.payInfo.signType,
            paySign: state.payInfo.paySign
          },
          function (res) {
            switch (res.err_msg) {
              case 'get_brand_wcpay_request:ok':
                if (state.actionType == 1) {
                  ctx.handlePayResult()
                }
                if (state.actionType == 2) {
                  ctx.handlePayResult()
                }

                break
              case 'get_brand_wcpay_request:cancel':
                state.show = false
                tool.toast(
                  {
                    msg: '取消充值',
                    duration: 1500
                  },
                  () => { }
                )
                break
              case 'get_brand_wcpay_request:fail':
                tool.toast(
                  {
                    msg: '充值失败',
                    duration: 1500
                  },
                  () => { }
                )
                break
              default:
                break
            }
          }
        )
      },
      // alipayjsapi调起支付宝支付函数
      async aliPayFn (params, res, type, config) {
        console.log('wxpay', res)
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
          payAmount: chargeMoney.value
        })
        switch (state.userInfo.device) {
          case 'ali':
            ap.tradePay(
              {
                orderStr: params
              },
              res => {
                const code = Number(res.resultCode)
                switch (code) {
                  case 9000:
                    if (state.actionType == 1) {
                      ctx.handlePayResult()
                    }
                    if (state.actionType == 2) {
                      ctx.handlePayResult()
                    }
                    break
                  case 800:
                    tool.toast(
                      {
                        msg: '正在处理中，请等待系统审核',
                        duration: 1500
                      },
                      () => {
                        router.push({
                          name: 'personal'
                        })
                      }
                    )
                    break
                  default:
                    tool.toast(
                      {
                        msg: '充值失败',
                        duration: 1500
                      },
                      () => { }
                    )
                    break
                }
              }
            )
            break
          case 'other':
            // 存储isCharge到sessionStorage
            setStore({
              name: 'isCharge',
              content: '1',
              type: 'session'
            })
            try {
              if (state.zhongtian && type == 2) {
                await nativeIntercation('sendPayInfo', Object.assign(res.extendOrderParam, {
                  payType: state.payType,
                  amount: config.trxamt,
                  timeStamp: new Date().getTime()
                }))
              }
            } catch (error) {
              console.log('传递支付参数异常')
            }
            const body = document.querySelector('body') // 原有支付
            const dom = new DOMParser().parseFromString(params, 'text/html')
            body.appendChild(dom.forms[0])
            document.forms[0].submit()
            break
          default:
            break
        }
      },
      // alipayjsapiSD调起支付宝支付函数
      aliPaySDFn (data) {
        switch (state.userInfo.device) {
          case 'ali':
            ap.tradePay(
              {
                tradeNO: data.tradeNO
              },
              res => {
                const code = Number(res.resultCode)
                switch (code) {
                  case 9000:
                    if (state.actionType == 1) {
                      ctx.handlePayResult()
                    }
                    if (state.actionType == 2) {
                      ctx.handlePayResult()
                    }
                    break
                  case 800:
                    tool.toast(
                      {
                        msg: '正在处理中，请等待系统审核',
                        duration: 1500
                      },
                      () => {
                        router.push({
                          name: 'personal'
                        })
                      }
                    )
                    break
                  default:
                    tool.toast(
                      {
                        msg: '充值失败',
                        duration: 1500
                      },
                      () => { }
                    )
                    break
                }
              }
            )
            break
          case 'other':
            // 存储isCharge到sessionStorage
            setStore({
              name: 'isCharge',
              content: '1',
              type: 'session'
            })
            window.location.href = `${data.url}${tool.params(data)}` // 杉德
            break
          default:
            break
        }
      },
      // aliPayTongLianFn调起支付宝支付函数
      aliPayTongLianFn (data) {
        console.log('aliPayTongLianFn--data:', data)
        ap.tradePay(
          {
            tradeNO: data.tradeNO
          },
          res => {
            const code = Number(res.resultCode)
            switch (code) {
              case 9000:
                if (state.actionType == 1) {
                  ctx.handlePayResult()
                }
                if (state.actionType == 2) {
                  ctx.handlePayResult()
                }
                break
              case 800:
                tool.toast(
                  {
                    msg: '正在处理中，请等待系统审核',
                    duration: 1500
                  },
                  () => {
                    router.push({
                      name: 'personal'
                    })
                  }
                )
                break
              default:
                tool.toast(
                  {
                    msg: '充值失败',
                    duration: 1500
                  },
                  () => { }
                )
                break
            }
          }
        )
      },
      // 余额支付
      walletPayOrderFn () {
        trackSubmit({
          eventCode: handleToLowerCase(state.theme) + '_recharge_pay_show',
          payAmount: chargeMoney.value
        })
        walletPayOrder({
          iccid: state.userInfo.iccid,
          packageId: ctx.setMealObject.packageId,
          packageName: ctx.setMealObject.packageName,
          orderAmount: chargeMoney.value,
          num: state.num
        }).then(({ data }) => {
          if (data.code == 0) {
            if (state.actionType == 1) {
              ctx.handlePayResult()
            }
            if (state.actionType == 2) {
              ctx.handlePayResult()
            }

            return
          }
          if (data.code == 1004) {
            return tool.alert(
              {
                title: '提示',
                msg: '余额不足，请充值'
              },
              () => {
                ctx.goChargeMoney()
              }
            )
          }

          tool.alert({
            title: '提示',
            msg: data.msg
          })
        })
      }
    }
    onMounted(() => {
      store.dispatch('SetUserInfo', { isConfigAppid: false })
      state.flowStatus = window.SITE_CONFIG.flowStatus || 'all'
      // 语音平台屏蔽 【生效时间 & 套餐信息】
      state.isShowPackageInfoTips = window.SITE_CONFIG.isShowPackageInfoTips || false
      // 获取主题名称
      state.theme = store.state.common.theme
      // 获取平台code 码
      state.platformCode = store.state.common.platformCode
      // 获取用户信息
      state.userInfo = store.state.user.userInfo
      // 使用sessionStorage获取存储的openId信息
      state.userOpenId = getStore({ name: 'userOpenId' })
      // h5支付结束
      const isCharge =
        getStore({
          name: 'isCharge'
        }) || '2'
      if (isCharge === '1') {
        tool.confirm(
          {
            title: '提示',
            msg: '充值结果系统处理中'
          },
          () => {
            window.history.back()
          }
        )
        removeStore({ name: 'isCharge', type: 'session' })
      }
    })
    return {
      ...toRefs(state),
      ...methods,
      tool,
      store,
      disabledCharge,
      chargeMoney
    }
  }
})
</script>
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";
@import "@/style/theme/index";

.confirmOrder {
  .content {
    padding-bottom: 180px;
  }

  .tips {
    color: #777;
    padding: 20px 3%;
  }

  .packageInfo {
    width: 92%;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;

    .item,.packageItem {
      height: 94px;
      line-height: 94px;
      margin: 0px 32px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 28px;
      border-bottom: 1px solid rgb(253, 249, 249);

      .left {
        width: 20%;
        color: #999;
      }

      .right {
        color: #333;
      }

      .desc_remark {
        color: #777;
        font-size: 28px;
      }

      .colorTetx {
        color: #f14153;
      }

      .colorTetx_ {
        @include font_color("font_color1");
      }

      .redColorTetx {
        font-weight: bold;
        color: #f14153;
      }

      &.acttips {
        height: 96px;
        // background-size: 345px 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FF281D;
        position: relative;
        background: rgba(255, 39, 29, 0.1);
        border-radius: 8px;
        margin: 0px 16px 16px 16px !important;
        font-size: 28px;

        img {
          width: 160px;
          height: 62px;
          position: absolute;
          right: 33px;
          top: 5px;
        }
      }
    }
    .item{
      .right {
        text-align: right;
        max-width: 80%;
        overflow-x: auto;
        white-space: nowrap;
      }
    }
    .packageItem{
      height: 13.533vw;
      line-height: 5.533vw;
      word-wrap: break-word;
      .right {
        overflow-x: hidden;
        white-space: pre-wrap;
      }
    }
  }

  .pay-methods {
    margin-top: 32px;

    /deep/.van-cell {
      height: 96px;
      background: #F7F7F7;
      ;
    }

    /deep/.van-cell:after {
      display: none;
    }

    .img-icon {
      width: 18px;
      height: 12px;
      display: inline-block;
    }

    /deep/.van-radio__icon--checked {
      .van-icon {
        color: #FFFFFF;
        background-color: red;
        border-color: red;
      }
    }
  }

  .payAction {
    width: 94%;
    padding: 20px 0;
    margin: auto;
    border-top: 1px solid #ececec;

    .payType {
      font-size: 24px;
      padding: 12px;
    }

    /deep/ .van-cell {
      padding: 5px;

      .van-cell__title {
        color: #222;
      }

      img {
        width: 48px;
        margin-right: 5px;
      }

      .goRecharge {
        padding: 3px 15px;
        border-radius: 1.5em;
        color: #fff;
        @include background_color("background_color1");
      }

      .moneyPay {
        display: flex;
        align-items: center;

        .money {
          margin-left: 10px;
          font-size: 24px;
          @include font_color("font_color1");
        }
      }
    }
  }

  .chargeBtn_box {
    width: 100%;
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #F7F7F7;
    overflow: hidden;
    .chargeBtn {
      width: 686px;
      height: 100px;
      @include background_color("background_color1");
      border: none !important;
      border-radius: 20px;
    }
  }
}

.pay-method-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 16px;
  }
}

/deep/.van-action-sheet__header {
  height: 96px;
  line-height: 96px;
}
</style>
