<template>
  <div class="mobile_content">
    <slot>
      <!-- 手机号码输入框  验证码按钮 -->
      <div class="mobile_num">
        <span class="desc">手机号码:</span>
        <input
          class="mobile_input"
          type="text"
          style="border: none"
          placeholder="请输入手机号码"
          v-model="mobile"
          maxlength="11"
        />
        <button
          class="verification_code"
          :class="{
            verification_code:
              mobile && mobile.length == 11 && timeStyle && !timeStyleZero,
            verification_code_:
              (mobile && mobile.length == 11 && !timeStyle && !timeStyleZero) ||
              (mobile && mobile.length == 11 && !timeStyle && timeStyleZero)
          }"
          :disabled="time != '获取验证码'"
          @click="getSms"
        >
          {{ time }}
        </button>
      </div>
    </slot>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted
} from "vue";
import { useRoute } from "vue-router";
import { getStore, removeStore } from "@/utils/store";
import {
  getMobileCode,
  AuthenTicationAuto,
  getMobileIsRealName
} from "_API_/api.services";
import { cardid, isvalidatemobile } from "@/utils/validate";
export default defineComponent({
  name: "VerificationCode",
  props: {},

  setup(props, context) {
    const state = reactive({
      card: "", //卡号
      cardId: "", //卡ID
      realName: "", //真实姓名
      IDCard: "", //身份证
      mobile: "", //手机号码
      VerifyCode: "", //验证码
      openIdcard: "", //openIdcard
      time: "获取验证码",
      timeId: "", //验证码定时器Id
      timeStyle: false, //验证码样式控制
      timeStyleZero: false, //验证码倒计时为0的样式控制
      type: 3, //类型
      testType: 1
    });
    // 这里的ctx  类似于vue2的ctx
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.type = route.query.pageType || 3;
    const methods = {
      //获取验证码
      getSms() {
        context.emit("emitNum", {
          mobile: state.mobile
        });
        //检测手机号
        if (!Boolean(state.mobile) || state.mobile.length < 11) {
          tool.alert({
            title: "提示",
            msg: "请输入正确的手机号"
          });
          return;
        }
        //验证手机号
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            msg: result_phone[1]
          });
          return;
        }
        //检测手机号码是否已经绑定过
        getMobileIsRealName({
          card: state.card,
          mobile: state.mobile
        }).then(({ data }) => {
          if (data.code == 0) {
            state.openIdcard = data.realName;
            return data;
          }
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        });
        //验证码接口
        getMobileCode({
          type: state.type,
          card: state.card,
          mobile: state.mobile,
          cardId: state.cardId
        }).then(({ data }) => {
          if (data.code == 0) {
            ctx.timeBack(60);
            tool.toast({
              msg: "发送成功",
              duration: 1500
            });
            return;
          }
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        });
      },
      //验证码回调
      timeBack(time) {
        if (time <= 0) {
          clearTimeout(state.timeId);
          return;
        }
        state.time = time--;
        state.timeStyle = true;
        state.time = time + "s";
        if (state.time === "0s") {
          state.timeStyleZero = true;
          state.timeStyle = false;
          state.time = "获取验证码";
        }
        state.timeId = setTimeout(() => {
          ctx.timeBack(time);
        }, 1000);
      },
      //三要素系统认证
      AuthenTicationAuto_() {
        //检测卡号 需要确认
        if (!Boolean(state.card)) {
          tool.alert({
            title: "提示",
            msg: "请输入卡号"
          });
          return;
        }
        if (!Boolean(state.realName)) {
          //检测真实姓名
          tool.alert({
            title: "提示",
            msg: "请输入真实姓名"
          });
          return;
        }
        //检测身份证
        let result_cardId = cardid(state.IDCard);
        if (result_cardId[0]) {
          tool.alert({
            title: "提示",
            msg: result_cardId[1]
          });
          return;
        }
        //验证手机号
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            msg: result_phone[1]
          });
          return;
        }
        //检测验证码
        if (!Boolean(state.VerifyCode)) {
          tool.alert({
            title: "提示",
            msg: "请输入验证码"
          });
          return;
        }
        //接口所需的参数
        let conf = {
          card: state.card,
          mobile: state.mobile,
          code: state.VerifyCode,
          realName: state.realName,
          IDCard: state.IDCard,
          testType: state.testType
        };
        // 系统实名认证
        AuthenTicationAuto(conf).then(({ data: res }) => {
          if (res.code == 0) {
            router.push({
              name: "realName-success"
            });
          } else if (res.code == 301) {
            tool.alert({
              title: "提示",
              msg: res.msg
            });
          } else if (res.code == 500) {
            router.push({
              name: "personal-authUpLoad"
            });
          } else {
            router.push({
              name: "realName-fail"
            });
          }
        });
      },
      //检测路由参数
      judgeRoute() {
        if (route.query.virtualId || route.query.iccid || route.query.card) {
          state.card =
            route.query.iccid || route.query.virtualId || route.query.card;
        }
      }
    };

    onMounted(() => {
      //获取用户信息
      const info = store.state.user.userInfo;
			// 当提示换卡成功 且 作为新卡使用时 若需要绑定手机号 则 检测路由参数
			if(getStore({ name: "changeCard2" })){
				ctx.judgeRoute();
				removeStore({ name: "changeCard2",type: "session" });
			}else if (Boolean(info.iccid) || Boolean(info.virtualId)) {//解绑时展示手机号码
        state.mobile = info.mobile;
        state.cardId = info.cardId || ""; //获取卡Id
        state.card = info.virtualId || info.iccid || ""; //获取卡号
      } else {
        ctx.judgeRoute();
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
});
</script>
<style lang="scss" scoped>
@import "@/style/theme/index";
.mobile_content {
  flex: 1;
  overflow: auto;
  .mobile_num {
    width: 600px;
    height: 88px;
    justify-content: space-between;
    background: #ffffff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .desc {
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
    }

    .mobile_input {
      width: 214px;
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
    }
    .verification_code {
      width: 170px;
      height: 50px;
      font: {
        size: 22px;
        weight: 400;
      }
      color: #ffffff;
      text-align: center;
      line-height: 50px;
      background: #aaaaaa;
      border-radius: 10px;
      border: none;
    }
    .verification_code_ {
      width: 170px;
      height: 50px;
      font: {
        size: 22px;
        weight: 400;
      }
      color: #ffffff;
      text-align: center;
      line-height: 50px;
      @include background_color("background_color1");
      border-radius: 10px;
      border: none;
    }
  }
}
</style>
