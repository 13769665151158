import instance from '_UTIL_/modules/http'

// 获取查询页轮播
export const mainBanner = () => {
  return instance({
    url: '/card/mainBanner',
    method: 'get'
  })
}

// 获取版本信息
export const getVersion = () => {
  return instance({
    url: '/version',
    method: 'post'
  })
}

// 微信埋点
// type   1公从号查询，2广告弹框，3进入下载页，4下载App
// 15个人中心引导换卡
// 16套餐充值引导换卡
// 17 首页滚动广告
export const advertLog = obj => {
  return instance({
    url: '/card/hd/advertLog',
    method: 'post',
    data: obj
  })
}
// 未读公告数 新
export const NoticeNum = card => {
  return instance({
    url: '/mymessage/unreadCount',
    method: 'post',
    data: { card }
  })
}
// 未读公告列表 新
export const NoticeList = obj => {
  return instance({
    url: '/mymessage/listOfUnreadAnnouncements',
    method: 'post',
    data: obj
  })
}

// 初始化jssdk的config
export const getconf = obj => {
  return instance({
    url: '/user/getconf',
    method: 'post',
    data: obj
  })
}

// 充值页 入口判断
export const queryCardSignContract = iccid => {
  return instance({
    url: 'card/signContract',
    method: 'post',
    data: { iccid }
  })
}

// 套餐详情
export const queryCardPackage = card => {
  return instance({
    url: 'card/v2/queryCardPackage',
    method: 'post',
    data: { card }
  })
}

// 用量详情
export const queryCardUsedInfo = card => {
  return instance({
    url: '/card/v2/queryCardUsedInfo',
    method: 'post',
    data: { card }
  })
}

// 检测卡
export const testCard = obj => {
  return instance({
    url: '/card/checkCard',
    method: 'post',
    data: obj
  })
}

// 卡诊断
export const diagnosis = (card, device) => {
  return instance({
    url: '/card/diagnosis',
    method: 'post',
    data: { card, device }
  })
}

// 查询卡号信息
export const queryCardInfo = (card, device) => {
  return instance({
    url: '/card/queryCardInfo',
    method: 'post',
    data: { card, device }
  })
}

// 查询卡状态
export const queryCardStatus = card => {
  return instance({
    url: '/card/queryCardStatus',
    method: 'post',
    data: { card }
  })
}
// 查询卡实名状态
export const qryCardRealName = cardId => {
  return instance({
    url: `/card/qryCardRealName?cardId=${cardId}`,
    method: 'get'
  })
}

// 获取实名地址
export const realNameUrl = card => {
  return instance({
    url: '/card/realNameUrl',
    method: 'post',
    data: { card }
  })
}

// 获取实名地址 (针对语音平台)
export const realNameUrlYuYin = (obj,noLoading) => {
    return instance({
      url: '/card/realNameUrl',
      method: 'post',
      data: obj,
      noLoading
    })
}

// 检测是否为多网卡
export const querySubCardByMultiCardId = card => {
  return instance({
    url: '/card/querySubCardByMultiCardId',
    method: 'get',
    params: { multiCardIccid: card }
  })
}

// 获取实名状态
export const queryCardReal = card => {
  return instance({
    url: '/card/queryCardReal',
    method: 'post',
    data: { card }
  })
}

// 公告列表
export const msgNoticeList = obj => {
  return instance({
    url: '/mymessage/msgNoticeList',
    method: 'post',
    data: obj
  })
}

// 通知信息列表
export const msgInformList = obj => {
  return instance({
    url: '/mymessage/msgInformList',
    method: 'post',
    data: obj
  })
}
// 通知信息列表已读
export const msgInformInfo = obj => {
  return instance({
    url: '/mymessage/msgInformInfo',
    method: 'post',
    data: obj
  })
}
// 公告信息列表已读
export const msgNoticeInfo = obj => {
  return instance({
    url: '/mymessage/msgNoticeInfo',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>查询卡信息
export const checkCard = obj => {
  return instance({
    url: '/wo/checkCard',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>未读客服回复数量
export const getUnreadTicketMessage = obj => {
  return instance({
    url: '/wo/getUnreadTicketMessage',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>工单列表
export const getWordOrders = obj => {
  return instance({
    url: '/wo/getWordOrders',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>工单群列表
export const knowledgeGroupList = obj => {
  return instance({
    url: '/wo/knowledgeGroupList',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>反馈类型列表
export const knowledgeList = obj => {
  return instance({
    url: '/wo/knowledgeList',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>反馈描述
export const descSubmit = obj => {
  return instance({
    url: '/wo/submit',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>反馈互动详情
export const getWordOrderDetail = obj => {
  return instance({
    url: '/wo/getWordOrderDetail',
    method: 'post',
    data: obj
  })
}

// 工单反馈=>反馈互动发送信息
export const chatMsg = obj => {
  return instance({
    url: '/wo/sendMsg',
    method: 'post',
    data: obj
  })
}

// 实名认证(系统实名上传验证)
export const AuthenTicationAuto = obj => {
  return instance({
    url: '/card/checkRealNameInfo',
    method: 'post',
    data: obj
  })
}

// 实名认证(手动上传验证)
export const AuthenTicationManaul = obj => {
  return instance({
    url: '/card/uploadCardRealNameInfo',
    method: 'post',
    data: obj
  })
}

// 套餐充值
export const queryPackages = obj => {
  return instance({
    url: '/card/queryPackages',
    method: 'post',
    data: obj
  })
}

// 余额充值=>微信充值
export const wxOrder = obj => {
  return instance({
    url: '/pay/wxOrder',
    method: 'post',
    data: obj
  })
}
// 余额充值=>支付宝充值
export const aliPayOrder = obj => {
  return instance({
    url: '/pay/aliPayOrder',
    method: 'post',
    data: obj
  })
}

// 余额充值=>支付购买
export const walletPayOrder = obj => {
  return instance({
    url: '/card/orderPackage',
    method: 'post',
    data: obj
  })
}

// 账单中心=>套餐记录
export const queryOrder = obj => {
  return instance({
    url: '/card/queryOrder',
    method: 'post',
    data: obj
  })
}
// 账单中心=>余额记录
export const userMoneyHistory = obj => {
  return instance({
    url: '/card/v2/userMoneyHistory',
    method: 'post',
    data: obj
  })
}

// 卡片管理=>修改昵称
export const updateNickname = obj => {
  return instance({
    url: '/card/updateNickname',
    method: 'post',
    data: obj
  })
}

// 白名单列表
export const voiceWhitelist = obj => {
  return instance({
    url: '/voiceWhitelist/list',
    method: 'post',
    data: obj
  })
}
// 同步白名单
export const synWhiteList = obj => {
  return instance({
    url: '/card/synWhiteList',
    method: 'post',
    data: obj
  })
}

// 提交保存新增白名单
export const saveWhiteList = obj => {
  return instance({
    url: '/voiceWhitelist/saveWhiteList',
    method: 'post',
    data: obj
  })
}

// 修改更新白名单
export const update = obj => {
  return instance({
    url: '/voiceWhitelist/update',
    method: 'post',
    data: obj
  })
}

// 重新添加白名单
export const returnAdd = obj => {
  return instance({
    url: '/voiceWhitelist/reAdd',
    method: 'post',
    data: obj
  })
}

// 取消添加白名单
export const finishWhiteList = obj => {
  return instance({
    url: '/voiceWhitelist/finishWhiteList',
    method: 'post',
    data: obj
  })
}

// 删除白名单
export const deleteWhiteList = obj => {
  return instance({
    url: '/voiceWhitelist/deleteWhiteList',
    method: 'post',
    data: obj
  })
}

// 停机保号套餐
export const queryStopPackage = card => {
  return instance({
    url: 'card/queryStopPackage',
    method: 'post',
    data: { card }
  })
}
// 绑定手机号码
export const bindMobile = obj => {
  return instance({
    url: '/card/bindMobile',
    method: 'post',
    data: obj
  })
}
// 解绑手机号码
export const unBindMobile = obj => {
  return instance({
    url: '/card/unBindMobile',
    method: 'post',
    data: obj
  })
}

// 注销卡片
export const cancelCard = obj => {
  return instance({
    url: '/card/cancelCard',
    method: 'post',
    data: obj
  })
}

// 检测手机号码是否已经绑定过
export const getMobileIsRealName = obj => {
  return instance({
    url: '/card/checkMobileIsRealName',
    method: 'post',
    data: obj
  })
}

// 获取验证码
export const getMobileCode = obj => {
  return instance({
    url: '/verify/sendCode',
    method: 'post',
    data: obj
  })
}

// 传code获取openId
export const getCode = obj => {
  return instance({
    url: '/pay/getOpenId',
    method: 'post',
    data: obj
  })
}

// 传code获取openId
export const getAliUserId = obj => {
  return instance({
    url: '/pay/getAliUserId',
    method: 'post',
    data: obj
  })
}

// 智能诊断=>新有效期检测
export const diagnosisEffective = card => {
  return instance({
    url: '/card/v2/diagnosisEffective',
    method: 'post',
    data: { card }
  })
}

// 智能诊断=>流量诊断
export const getDiagnosis = card => {
  return instance({
    url: '/card/diagnosis',
    method: 'post',
    data: { card }
  })
}

// 智能诊断=>新流程状态诊断
export const diagnosisCardStatus = card => {
  return instance({
    url: '/card/v2/diagnosisCardStatus',
    method: 'post',
    data: { card }
  })
}

// 套餐生效
export const GetEffectPackage = card => {
  return instance({
    url: '/card/effectPackage',
    method: 'post',
    data: { card }
  })
}
// 一键优化
export const GetStopRestart = card => {
  return instance({
    url: '/card/stopRestart',
    method: 'post',
    data: { card }
  })
}

// 检测换卡日期
export const checkExchangeCardTime = card => {
  return instance({
    url: 'card/checkExchangeCardTime',
    method: 'post',
    data: { card }
  })
}

// 换卡
export const ExhchangeCard = (conf, status) => {
  return instance({
    url: `/card/${status == 3 ? 'ExchangeFaultCard' : 'faultCardAddress'}`,
    method: 'post',
    data: conf
  })
}

// 机卡绑定列表
export const CardSeparationInfo = card => {
  return instance({
    url: '/card/cardSeparationInfo',
    method: 'post',
    data: { card }
  })
}
// 提交 机卡绑定
export const submitChangeImei = conf => {
  return instance({
    url: '/card/subtimeCardSeparation',
    method: 'post',
    data: conf
  })
}

// 获取广告
export const zyAdBannerList = (code, iccid) => {
  return instance({
    url: '/api/zyadposition/zyAdBannerByCodeList',
    method: 'get',
    params: {
      code,
      iccid
    }
  })
}

// 获取广告信息
export const zyAdBannerInfo = code => {
  return instance({
    url: `/api/zyadposition/zyAdPositionByCodeInfo/${code}`,
    method: 'get'
  })
}

// 获取微信小程序schema
export const fetchWxSchema = data => {
  return instance({
    url: '/pay/getUrlScheme',
    method: 'post',
    data
  })
}

/*
 电信实名认证接口相关
 */
// 信息认证
export const authInfoCheck = data => {
  var baseUrl = window.SITE_CONFIG.newTelecomAuth
  return instance.request({
    url: `${baseUrl}/file/newChinaTelecom/authInfoCheck`,
    method: 'post',
    data
  })
}
// 身份证正反面
export const ocrCardCheck = data => {
  var baseUrl = window.SITE_CONFIG.newTelecomAuth
  return instance({
    url: `${baseUrl}/file/newChinaTelecom/ocrCardCheck`,
    method: 'post',
    data
  })
}

// 视频认证
export const checkAuthVideo = data => {
  var baseUrl = window.SITE_CONFIG.newTelecomAuth
  return instance({
    url: `${baseUrl}/file/newChinaTelecom/checkAuthVideo`,
    method: 'post',
    data,
    timeout: 100000
  })
}

// 上传接口 [暂不用了]
export const fileUpload = data => {
  return instance({
    url: '/card/upload',
    method: 'post',
    data,
    timeout: 100000
  })
}

// 获取认证请求参数
export const fetchAuthParams = data => {
  return instance({
    url: `/card/getPassagewayParams/${data}`,
    method: 'get'
  })
}

// 推送视频认证结果
export const pushAuthResult = data => {
  return instance({
    url: '/card/realNameResultPush',
    method: 'post',
    data
  })
}

// 电信卡解锁
export const realNameRecIot = data => {
  return instance({
    url: '/card/realNameRecIot',
    method: 'post',
    data
  })
}

// 获取优惠券弹框
export const getDialogCoupon = (data) => {
  return instance({
    url: '/card/getRemindCouponListByIccid',
    method: 'post',
    data
  })
}

// 弹窗提醒通用
export const remindCoupon = data => {
  return instance({
    url: '/card/updateRemindCouponByIccid',
    method: 'post',
    data
  })
}
// 获取客服联系方式
export const serviceContact = data => {
  return instance({
    url: '/card/getCardCustomerServiceContact',
    method: 'get',
    params: { iccid: data }
  })
}
// 充值引导开关配置
export const querySysParamValues = obj => {
  return instance({
    url: '/api/querySysParamValues',
    method: 'post',
    data: obj
  })
}
// 成交消息通知
export const queryPackageRechargeMessageList = obj => {
  return instance({
    url: '/card/queryPackageRechargeMessageList',
    method: 'post',
    data: obj
  })
}

// 获取系统参数最低金额配置
export const minRechargeAmount = () => {
  return instance({
    url: '/api/getParamsValueByKey/minRechargeAmount',
    method: 'get'
  })
}

// 页面不展示iccid 的 实名链接获取
export const pageNoShowIccidFetchRealUrl = (data) => {
    return instance({
      url: '/card/realNameUrl',
      method: 'post',
      data: { 
        ...data,
      }
    })
}
