const getters = {
  theme: state => state.common.theme,
  tongLianInfo: state => state.user.tongLianInfo,
  isSimple: state => state.user.isSimple,
  platformCode: state => state.common.platformCode,
  showLoading: state => state.common.showLoading,
  loginAccessToken: state => state.user.loginAccessToken,
  queryIccid: state => state.user.queryIccid,
  queryVirtualId: state => state.user.queryVirtualId,
  userList: state => state.user.userList,
  userInfo: state => state.user.userInfo
};
export default getters;
