<template>
  <div class="platfrom-coupon-index">
    <van-popup v-model:show="couponPopup" :close-on-click-overlay="false" class="coupon-popup" style="background-color: transparent;">
      <div class="popup-content">
        <div class="coupon-list">
          <img :src="bgImg" class="head-img" alt="">
          <div class="ul-coupon">
            <div class="li-coupon" v-for="item in dialogCoupon" :key="item.couponId">
              <div class="li-l">
                <div class="price">
                  ¥<span class="num">{{String(item.couponAmount).split('.')[0]}}</span>
                  <span v-if="String(item.couponAmount).split('.')[1]">.{{String(item.couponAmount).split('.')[1]}}</span>
                </div>
                <div>满{{item.fullUse}}可用</div>
              </div>
              <div class="li-r">
                <div class="li-name">{{item.couponTitle}}</div>
                <div class="time">
                  {{item.expirationDate}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="coupon-footer">
          <div class="f-btn" @click="toPath()">查看我的券</div>
          <div class="f-coupon-btn" @click.stop="handleToPath()">前往充值 <img src="../../assets/image/coupon/home_coupon_arrow.png" alt=""></div>
        </div>
        <div class="close-img">
          <img @click.stop="couponPopup = false" src="../../assets/image/coupon/coupon_close_iocn.png" alt="">
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { reactive, toRefs,defineComponent,getCurrentInstance } from 'vue' 
  export default defineComponent({
    name: "PlatfromCoupon",
    setup() {
      const state = reactive({
        couponPopup: false,
        bgImg: require('../../assets/image/coupon/home_coupon_bg_icon.png'),
        dialogCoupon: [],
      })
      const {proxy: ctx} = getCurrentInstance()
      const { $router: router, $store: store, } = ctx;

      const methods = {
        init(list = []){
          state.couponPopup = true;
          state.dialogCoupon = list;
        },
        toPath(){
          state.couponPopup = false;
          router.push({
            name: 'couponList'
          })
        },
        handleToPath(){
          let val = store.state.common.theme
          if(val == 'AnFang' || val == 'YuYin'){
            state.couponPopup = false;
          }else {
            router.push({
              name: "personal-packageRecharge", //前往套餐充值
              query: { type: 0 }
            });
          }
        },
      }

      return { 
        ...toRefs(state), 
        ...methods,
      };
    }
  })

</script>

<style lang="scss" scoped>
  .coupon-popup {
    background-color: transparent;
  }
  .close-img {
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: right;
    img {
      width: 56px;
      height: 56px;
    }
  }
  .popup-content {
    width: 660px;
    padding: 0px 30px;
    box-sizing: border-box;
    position: relative;
    font-family: "PingFang SC";
    .coupon-list {
      width: 100%;
      height: 100%;
      padding: 88px 0px 0px;
      box-sizing: border-box;
      margin-bottom: -2px;
      .head-img {
        width: 100%;
        margin-bottom: -10px;
      }
      .ul-coupon {
        width: 100%;
        padding: 16px 20px;
        background: linear-gradient(90deg, rgba(231,44,30,1) 0%, rgba(244,77,35,1) 52%, rgba(231,44,30,1) 100%);;
        max-height: 520px;
        overflow-y: auto;
        box-sizing: border-box;
        .li-coupon {
          width: 100%;
          height: 130px;
          padding: 0px 16px;
          background: linear-gradient(133.9deg, rgba(255,255,255,1) 0%, rgba(255,244,223,1) 100%);
          box-sizing: border-box;
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          border-radius: 16px;
          .li-l {
            font-size: 24px;
            color: #6F5B38;
            padding-right: 16px;
            border-right: 2px dashed #EBDCC1;
            height: 95%;
            padding-top: 14px;
            box-sizing: border-box;
            width: 172px;
            box-sizing: border-box;
            text-align: center;
            .price {
              color: #FE4135;
              text-align: center;
              .num {
                font-size: 48px;
                font-weight: 500;
              }
            }
          }
          .li-r {
            padding-left: 16px;
            font-size: 24px;
            .li-name {
              max-width: 344px;
              color: #000;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-bottom: 10px;
            }
            .time {
              color: #6F5B38;
            }
          }
        }
      }
    }
    .coupon-footer {
      width: 100%;
      height: 112px;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      box-sizing: border-box;
      background: linear-gradient(90deg, rgba(231,44,30,1) 0%, rgba(244,77,35,1) 52%, rgba(231,44,30,1) 100%);
      border-radius: 0 0 16px 16px;
      .f-coupon-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 114px;
        flex: 1;
        height: 72px;
        background: linear-gradient(180deg, rgba(254,249,226,1) 0%, rgba(255,193,72,1) 100%);
        box-shadow: 0 8px 8px 0 rgba(214,25,0,1);
        font-size: 28px;
        color: #EB0E00;
        img {
          width: 32px;
          height: 32px;
          margin-left: 20px;
        }
      }
      .f-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #EBDBC1;
        width: 194px;
        height: 72px;
        border-radius: 114px;
        font-size: 28px;
        box-sizing: border-box;
        margin-right: 16px;
        color: #EBDBC1;
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
      }
    }
  }

</style>