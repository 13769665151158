<template>
  <div class="container">
    <div class="appbar" v-if="appbar">
      <van-nav-bar :style="{ background: background }" :left-text="textLeft">
        <template v-if="showIcon" #right>
          <slot>
            <div class="icon" :style="`justify-content:${apiCode == 3001 ? 'flex-end': ''}`">
              <div v-if="apiCode != 3001" class="left" @click="to_asyncList">
                <img
                  src="../../assets/image/whiteList/icon_whitelist_rotate.png"
                  alt=""
                />
              </div>
              <div class="right" @click="addNum">
                <img
                  src="../../assets/image/whiteList/icon_whitelist_avatar.png"
                  alt=""
                />
              </div>
            </div>
          </slot>
        </template>
      </van-nav-bar>
    </div>
    <div class="statusBox">
      <div class="statusData">
        <div>{{isShowListAll ? '已显示全部状态' : '当前显示：生效中、处理中'}}</div>
        <div class="handleStatus" @click="isShowListAll ? handleStatus() : handleStatusAll()">{{isShowListAll ? '恢复默认' : '查看全部'}}</div>
      </div>
    </div>
    <div class="content">
      <slot>
        <div class="whiteList_box">
          <div
            class="whiteList_content"
            :class="{ listEmpty: list.length == 0 }"
          >
            <div class="desc">
              <div>昵称</div>
              <div>电话号码</div>
              <div>状态</div>
              <div>操作</div>
            </div>
            <div class="empty_box" v-if="list.length == 0">
              <div class="empty">暂无数据</div>
            </div>
            <div
              class="desc_detail_box"
              :class="{ active: editItem !== null }"
              v-else
            >
              <div class="listWrap">
                <div
                  class="desc_detail"
                  :class="{ editItem: editItem && item.id == editItem.id }"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <div class="desc_name">{{ item.phoneName }}</div>
                  <div class="desc_phone">{{ item.phone }}</div>
                  <div :class="'status' + item.status">
                    {{ parseStatus(Number(item.status)) }}
                  </div>
                  <div class="edit_box">
                    <div
                      class="desc_elp"
                      v-if="
                        item.status == 0 || item.status == 1 || item.status == 3
                      "
                      @click="
                        e => {
                          edit(item, index, e);
                        }
                      "
                    >
                      ...
                    </div>
                    <div
                      v-if="editItem && editItem.id == item.id"
                      class="click_box"
                      :style="`top:${clickBoxTop}px`"
                    >
                      <div @click="updateHandle(item)" v-if="item.status != 3">
                        修改
                      </div>
                      <div
                        v-if="item.status == 1 && isDelete"
                        @click="deleteCard(item)"
                      >
                        删除
                      </div>
                      <div v-if="item.status == 0" @click="reAdd(item)">
                        重新添加
                      </div>
                      <div v-if="item.status == 3" @click="cancel(item)">
                        取消添加
                      </div>
                    </div>
                    <div
                      class="backDrop"
                      v-if="editItem && editItem.id == item.id"
                      @click="clickMask"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dial_phone">
            <div class="desc_add">
              <img
                src="../../assets/image/whiteList/icon_blue_add.png"
                alt=""
              />
            </div>
            <div @click="addNum">添加要拨打的新号码</div>
          </div>
        </div>
        <van-popup v-model:show="beforeUpdate.visible">
          <list-modify
            :name="dataForm.phoneName"
            :mobile="dataForm.phone"
            :status="dataForm.status"
            @show="closeHandle"
          >
          </list-modify>
        </van-popup>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted
} from "vue";
import {
  voiceWhitelist,
  synWhiteList,
  update,
  returnAdd,
  finishWhiteList,
  deleteWhiteList
} from "_API_/api.services";
export default defineComponent({
  name: "WhiteList",
  props: {
    appbar: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: ""
    },
    textLeft: {
      type: String,
      default: ""
    }
  },
  setup() {
    const state = reactive({
      editItem: null, //编辑按钮
      cardId: "", //卡片id
      card: "", //卡号
      page: 1000, //条数
      list: [], //白名单列表
      tempList: [], //白名单列表 【默认生效中、处理中】
      maxWhitelist: "", //卡号所能添加的最大白名单数量
      show: false, //弹框展示
      listIndex: "",
      dataForm: {
        id: 0,
        phone: "",
        cardId: "",
        phoneName: ""
      },
      beforeUpdate: {
        visible: false, //弹框展示
        nickname: "" //昵称
      }, //昵称更新前
      clickBoxTop: 0,
      passagewayType: "", //通道类型
      operator: "",
      apiCode: '',
      isDelete: false, //是否可删除
      isShowListAll: false // 是否展示所有数据
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    const methods = {
      // 状态数据筛选 【默认生效中、处理中】
      handleStatus(){
        state.isShowListAll = !state.isShowListAll
        state.list = [...state.tempList]
      },
      // 状态数据筛选 【查看全部】
      handleStatusAll(){
        state.isShowListAll = !state.isShowListAll
        if (state.isShowListAll){
          //获取白名单列表
          ctx.getDataList();
        }
      },
      //关闭弹窗组件
      closeHandle(msg) {
        state.beforeUpdate.visible = msg.show_notice;
        state.listIndex = "";
        if (msg.nickname !== "") {
          state.dataForm.phoneName = msg.nickname;
          update(state.dataForm).then(({ data: res }) => {
            if (res.code == 0) {
              tool.toast(
                {
                  msg: "白名单修改成功",
                  duration: 1500,
                  icon: "success"
                },
                () => {
                  Object.assign(state.dataForm, {
                    id: 0,
                    phone: "",
                    phoneName: ""
                  });
                  ctx.getDataList();
                }
              );
              return;
            }
            Object.assign(state.dataForm, { id: 0, phone: "", phoneName: "" });
            tool.toast(
              {
                msg: res.msg,
                duration: 1500
              },
              () => {}
            );
          });
        }
        if (msg.nickname !== "" && msg.telNum !== "") {
          Object.assign(state.dataForm, {
            phone: msg.telNum,
            phoneName: msg.nickname
          });

          if (!state.dataForm.phoneName) {
            tool.toast(
              {
                msg: "请输入名称",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          if (!state.dataForm.phone && !state.dataForm.id) {
            tool.toast(
              {
                msg: "请输入手机号",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          if (!/^[0-9]*$/.test(state.dataForm.phone)) {
            tool.toast(
              {
                msg: "手机号格式不对请重新输入",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          update(state.dataForm).then(({ data: res }) => {
            if (res.code == 0) {
              tool.toast(
                {
                  msg: !state.dataForm.id ? "白名单添加成功" : "白名单修改成功",
                  duration: 1500,
                  icon: "success"
                },
                () => {
                  Object.assign(state.dataForm, {
                    id: 0,
                    phone: "",
                    phoneName: ""
                  });
                  ctx.getDataList();
                }
              );
              return;
            }
            Object.assign(state.dataForm, { id: 0, phone: "", phoneName: "" });
            tool.toast(
              {
                msg: res.msg,
                duration: 1500
              },
              () => {}
            );
          });
        }
      },
      //修改白名单
      updateHandle(row) {
        state.beforeUpdate.visible = true;
        Object.assign(state.dataForm, {
          id: row.id,
          cardId: row.cardId,
          phone: row.phone,
          phoneName: row.phoneName
        });
        if (row.id) {
          state.dataForm = JSON.parse(JSON.stringify(row));
        }
      },
      //判断删除
      judgeDelete() {
        if (
          state.operator !== 1 &&
          (state.passagewayType == 2 ||
            state.passagewayType == 20 ||
            state.passagewayType == 13)
        ) {
          state.isDelete = true;
        }
      },
      //删除白名单
      deleteCard({ id, cardId }) {
        if (!state.isDelete) {
          return tool.toast({
            msg: `该资源不允许删除白名单`,
            duration: 1500
          });
        }
        deleteWhiteList({ id, cardId }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "删除成功",
                duration: 1500
              },
              () => {
                Object.assign(state.dataForm, {
                  id: 0,
                  phone: "",
                  phoneName: ""
                });
                ctx.getDataList();
              }
            );
            return;
          }
          Object.assign(state.dataForm, { id: 0, phone: "", phoneName: "" });
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => {}
          );
          ctx.getDataList();
        });
      },
      //重新添加白名单
      reAdd({ id }) {
        returnAdd({
          id
        }).then(({ data: res }) => {
          if (res.code == 0) {
            ctx.getDataList();
            tool.toast(
              {
                msg: "重新添加成功",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => {}
          );
        });
      },
      //取消添加白名单
      cancel({ id }) {
        tool.confirm(
          {
            title: "提示",
            msg: "取消以后将不会添加成功，无法拨打此号码，请确认"
          },
          () => {
            finishWhiteList({ id }).then(({ data: res }) => {
              if (res.code == 0) {
                ctx.getDataList();
                tool.toast(
                  {
                    msg: "取消添加成功",
                    duration: 1500
                  },
                  () => {}
                );
                return;
              }
              tool.toast(
                {
                  msg: "取消以后将不会添加成功，无法拨打此号码，请确认",
                  duration: 1500
                },
                () => {}
              );
            });
          }
        );
      },
      //获取白名单列表
      getDataList() {
        let conf = new FormData();
        conf.append("cardId", state.cardId);
        conf.append("limit", state.page);
        voiceWhitelist(conf).then(({ data: res }) => {
          if (res.code == 0) {
            //手机号隐去中间四个字符后的list
            if (state.isShowListAll) {
              return state.list = tool.hideMobile(res.list)
            }
            // 默认显示生效中、处理中的数据
            state.tempList = state.list = tool.hideMobile(res.list).filter((item) => {
              return item.status == 1 || item.status == 2
            })
            console.log('state.tempList:',state.tempList);
          }
        });
      },
      //同步白名单
      asyncList() {
        synWhiteList({ cards: state.card }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast({
              msg: `白名单同步成功`,
              duration: 1500
            });
            ctx.getDataList();
            return;
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => {}
          );
        });
      },
      //顶部手动点击同步白名单
      to_asyncList() {
        synWhiteList({ cards: state.card }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "同步白名单成功",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          tool.toast(
            {
              msg: "同步白名单失败",
              duration: 1500
            },
            () => {}
          );
        });
      },
      //状态列表
      parseStatus(val) {
        switch (val) {
          case 0:
            return "已删除";
          case 1:
            return "已添加";
          case 2:
            return "处理中";
          case 3:
            return '已失败'
          case 4:
            return "已取消";
          case 5:
            return '在途单'
          default:
            return "处理中";
        }
      },
      //添加新号码
      addNum() {
        if (state.list.length > state.maxWhitelist - 1) {
          tool.toast(
            {
              msg: `最多只能添加${state.maxWhitelist}个白名单`,
              duration: 1500
            },
            () => {}
          );
          return;
        }
        router.push({
          name: "personal-whiteListAdd",
          query: {
            length: state.list.length
          }
        });
      },
      // 点击编辑
      edit(item, index, e) {
        let scrollTop = document.querySelector(".desc_detail_box").scrollTop;
        let offTop = document.querySelectorAll(".desc_detail")[index].offsetTop;
        let cH =
          document.querySelectorAll(".desc_detail")[index].clientHeight * 0.6;
        state.clickBoxTop = offTop + cH - scrollTop;
        state.editItem =
          state.editItem && state.editItem.id == item.id ? null : item;
      },
      clickMask() {
        state.editItem = null;
        document.querySelector(".listWrap").style.paddingBottom = "";
      }
    };
    onMounted(() => {
      //获取用户信息
      const info = store.state.user.userInfo;
      state.apiCode = info.apiCode
      Object.assign(state, {
        cardId: info.cardId || "", //获取卡id
        card: info.virtualId || info.iccid, //获取卡号
        maxWhitelist: info.maxWhitelist, //卡号所能添加的最大白名单数量
        passagewayType: info.passagewayType, //通道类型
        operator: info.operator
      });
      //获取白名单列表
      ctx.getDataList();
      //判断白名单是否可删除
      ctx.judgeDelete();
      //同步白名单 非全国联通
      if(state.apiCode != 3001){
        ctx.asyncList();
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";
@import "@/style/theme/index";
.container {
  display: flex;
  flex-direction: column;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    box-shadow: 1px 2px 3px #eeeeee;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
    .icon {
      width: 140px;
      height: 50px;
      display: flex;
      justify-content: space-around;
      .left img {
        width: 45px;
        height: 46px;
      }
      .right img {
        width: 45px;
        height: 46px;
      }
    }
  }
  .statusBox{
    padding: 20px 30px 0px 30px;
    .statusData{
      display: flex;
      justify-content: space-between;
      .handleStatus{
        @include font_color("font_color1");
      }
    }
  }
  .content {
    flex: 1;
    overflow: auto;
    height: 100vh;
    .whiteList_box {
      height: calc(100vh - 106px);
      overflow-y: auto;
      .whiteList_content {
        width: 690px;
        height: 78vh;
        overflow-y: auto;
        background: #ffffff;
        margin: 30px auto;
        border-radius: 20px;
        .desc {
          width: 630px;
          height: 128px;
          background: #ffffff;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 30px 0px 30px;
          div {
            font-size: 26px;
            font-weight: 400;
            color: #aaaaaa;
          }
        }
        .desc_detail_box,
        .active {
          .desc_detail {
            width: 690px;
            padding: 40px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px -4px 10px 0px #f1f2f3;
            border-radius: 20px;
            .desc_name {
              width: 100px;
              word-break: break-all;
              font-size: 30px;
              font-weight: 400;
              color: #333333;
              text-shadow: 0px -4px 10px #f1f2f3;
            }
            .desc_phone {
              width: 200px;
              font-size: 30px;
              font-weight: 400;
              color: #333333;
              text-shadow: 0px -4px 10px #f1f2f3;
            }
            .status0 {
              color: #c0c0c0;
              padding-right: 60px;
            }
            .status1 {
              color: #3399ff;
              padding-right: 60px;
            }
            .status2,
            .status3,
            .status5 {
              color: red;
              padding-right: 60px;
            }
            .status2,
            .status5 {
              padding-right: 98px;
            }
            .desc_adding {
              font-size: 30px;
              font-weight: 400;
              color: #fc992c;
              text-shadow: 0px -4px 10px #f1f2f3;
            }
            .edit_box {
              position: relative;
              overflow: initial;
              .desc_elp {
                font-size: 26px;
                font-weight: bold;
                color: #aaaaaa;
                height: 40px;
                line-height: 20px;
                padding-right: 16px;
              }
              .click_box {
                width: 150px;
                display: flex;
                flex-direction: column;
                text-align: center;
                background: #fff;
                box-shadow: 0 0 6px 2px #e5e5e5;
                z-index: 5;
                position: fixed;
                top: 44px;
                right: 4vw;
                div {
                  font: {
                    size: 30px;
                  }
                  height: 72px;
                  line-height: 72px;
                  border-top-color: #f2f2f2;
                  border-top-style: solid;
                  border-top-width: 1px;
                  border-left-color: #f2f2f2;
                  border-left-style: solid;
                  border-left-width: 1px;
                  border-right-color: #f2f2f2;
                  border-right-style: solid;
                  border-right-width: 1px;
                }
              }
              .backDrop {
                position: fixed;
                width: 100vw;
                height: 100vh;
                z-index: 2;
                top: 0;
                left: 0;
              }
            }
          }
          .editItem {
            background: #f7f7f7;
          }
        }
        .desc_detail_box {
          height: calc(78vh - 128px);
          overflow-y: auto;
          transition: all 0.15s linear;
          .listWrap {
            background: #fcfcfc;
          }
        }
        .active {
          overflow-y: hidden;
        }
      }
      .listEmpty {
        width: 690px;
        height: 20vh;
        overflow-y: auto;
        background: #ffffff;
        margin: 30px auto;
        border-radius: 20px;
        .desc {
          width: 630px;
          height: 128px;
          background: #ffffff;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 30px 0px 30px;
          div {
            font-size: 26px;
            font-weight: 400;
            color: #aaaaaa;
          }
        }
        .empty_box {
          height: calc(20vh - 128px);
          line-height: calc(20vh - 128px);
          overflow-y: auto;
          .empty {
            color: #ccc;
            text-align: center;
          }
        }
      }
      .dial_phone {
        width: 690px;
        height: 84px;
        @include background_color("background_color1");
        border-radius: 20px;
        margin: 50px auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .desc_add {
          width: 100px;
          display: flex;
          justify-content: space-around;
          img {
            width: 42px;
            height: 42px;
          }
        }
        div {
          font-size: 30px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .whiteList_popup {
    width: 590px;
    height: 869px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .goauthenticationImg {
      padding-top: 20px;
      text-align: center;
      img {
        width: 172px;
        height: 170px;
      }
    }
    .goauthenticationTitle {
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
      text-align: center;
      padding: 0 26px;
    }
    .tips_desc {
      width: 530px;
      height: 310px;
      border-radius: 20px;
      border: 5px dashed #aaaaaa;
      margin: 0px auto 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .tips_title {
        font: {
          size: 22px;
          weight: 400;
        }
        @include border_color("border_color1");
        border-bottom: 1px solid #2196f3;
      }
      .tips_content {
        font: {
          size: 22px;
          weight: 400;
        }
        color: #aaaaaa;
        padding: 24px;
      }
    }
    .viewDetail {
      text-align: center;
    }
  }
}
//实名弹窗样式
.auth_popup {
  width: 590px;
  height: 869px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .goauthenticationImg {
    padding-top: 20px;
    text-align: center;
    img {
      width: 172px;
      height: 170px;
    }
  }
  .goauthenticationTitle {
    font: {
      size: 30px;
      weight: 400;
    }
    color: #333333;
    text-align: center;
    padding: 0 26px;
  }
  .tips_desc {
    width: 530px;
    height: 310px;
    border-radius: 20px;
    border: 5px dashed #aaaaaa;
    margin: 0px auto 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .tips_title {
      font: {
        size: 22px;
        weight: 400;
      }
      color: #2196f3;
      border-bottom: 1px solid #2196f3;
    }
    .tips_content {
      font: {
        size: 22px;
        weight: 400;
      }
      color: #aaaaaa;
      padding: 24px;
    }
  }
  .viewDetail {
    text-align: center;
  }
}

.van-nav-bar__text {
  color: #ffffff;
}
.van-nav-bar__right {
  padding: 0px 10px;
}
.van-button--primary {
  width: 530px;
  height: 84px;
  @include background_color("background_color1");
  border: none;
  box-shadow: 0px 8px 16px 0px #bce1ff;
  border-radius: 20px;
}
</style>
