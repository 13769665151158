<template>
  <div class="container">
    <div class="appbar" v-if="appbar">
      <van-nav-bar
        :style="{ background: background, color: color }"
        :left-arrow="leftArrow"
        :title="title"
        :left-text="textLeft"
        :right-text="textRight"
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template v-if="showIcon" #right>
          <slot name="icon">
            <van-icon name="plus" size="18" />
          </slot>
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "AppContainer",
  props: {
    leftArrow: {
      type: Boolean,
      default: true,
    },
    appbar: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#fff",
    },
    textLeft: {
      type: String,
      default: "",
    },
    textRight: {
      type: String,
      default: "",
    },
    back: {
      type: String,
      default: "",
    },
  },
  setup(prop, ctx) {
    const router = useRouter();
    const onClickLeft = () => {
      router.back();
      ctx.emit("click-left");
    };
    const onClickRight = () => {
      ctx.emit("click-right");
    };
    return {
      onClickLeft,
      onClickRight,
    };
  },
});
</script>
<style lang="scss">
@import "~@/assets/style/mixin.scss";

.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    box-shadow: 1px 2px 3px #eeeeee;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  .content {
    flex: 1;
    overflow: auto;
  }
}
</style>
