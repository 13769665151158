// 扫描所有的组件
const files = require.context('./', true, /^\.\/[\w-_]+\/index\.(js|vue)$/)

const components = files.keys().map(key => {
  return files(key).default
})

export default {
  install (vm) {
    components.forEach(component => {
      vm.component(component.name, component)
    })
  }
}
