// 公用字典库
const DIC = {
  /**
   * 主题列表
   * XiaoMing 小茗--橙色->#F57C00
   * TianPeng 天蓬--蓝色->#2196F3
   * ZhongXun 中讯--青绿->#18B2A3
   * AnFang 安防--绿色->#377DFE
   * YuYin 语音--靛蓝->#00BCD4
   */
  ThemeList: [
    {
      name: "XiaoMing",
      color: "#F57C00",
      value: "theme-XiaoMing"
    },
    {
      name: "TianPeng",
      color: "#2196F3",
      value: "theme-TianPeng"
    },
    {
      name: "ZhongXun",
      color: "#18B2A3",
      value: "theme-ZhongXun"
    },
    {
      name: "AnFang",
      color: "#377DFE",
      value: "theme-AnFang"
    },
    {
      name: "YuYin",
      color: "#FE3A39",
      value: "theme-YuYin"
    }
  ],
  /**
   * 运营商
   * 1 电信 2 移动 3 联通 4 聚合
   */
  operatorType: [
    {
      type: 1,
      label: "中国电信 "
    },
    {
      type: 2,
      label: "中国移动"
    },
    {
      type: 3,
      label: "中国联通"
    },
    {
      type: 4,
      label: "聚合卡"
    }
  ],
  /**
   * 套餐记录订单类型
   * type: 1-首次充值 2-续费 3-接口首次 4-接口续费 5-批量续费 6-停机保号 7-自带套餐 8-批量首次
   */
  orderType: [
    {
      type: 1,
      label: "首次充值 "
    },
    {
      type: 2,
      label: "续费"
    },
    {
      type: 3,
      label: "接口首次"
    },
    {
      type: 4,
      label: "接口续费"
    },
    {
      type: 5,
      label: "批量续费"
    },
    {
      type: 6,
      label: "停机保号"
    },
    {
      type: 7,
      label: "自带套餐"
    },
    {
      type: 8,
      label: "批量首次"
    }
  ],
  /**
   * 余额记录流水类型
   * type: 流水类型 1 加减款 2 充值  3 提现 4 退款 5 会员减款
   */
  flowType: [
    {
      type: 1,
      label: "加减款 "
    },
    {
      type: 2,
      label: "余额充值"
    },
    {
      type: 3,
      label: "订购套餐"
    },
    {
      type: 4,
      label: "订单/余额退款"
    },
    {
      type: 5,
      label: "会员减款"
    }
  ],
  changeIme: ["未处理", "已提交 ", "成功", "失败"],
  // 卡片状态类型
  cardStatus: [
    {
      type: -1,
      label: "错误数据",
      text: {
        msg: "当前卡片状态异常，请稍后刷新重试或联系客服",
        msg_: "状态不可操作",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 0,
      label: "空卡",
      text: {}
    },
    {
      type: 1,
      label: "可激活",
      text: {},
      usageText: {
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值",
        bothMsg: "当前卡片流量与语音已用完或即将用完，如需使用，请尽快充值",
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //未实名 有套餐 用量超过90% 提醒文本
      isNewCardText: {
        msg_: "当前卡片为新卡，需要充值以后才可以正常使用",
        router_: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      } //未实名 无套餐 为新卡 提醒文本
    },
    {
      type: 2,
      label: "已激活",
      text: {},
      usageText: {
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值",
        bothMsg: "当前卡片流量与语音已用完或即将用完，如需使用，请尽快充值",
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //未实名 有套餐 用量超过90% 提醒文本
      isNewCardText: {
        msg_: "当前卡片为新卡，需要充值以后才可以正常使用",
        router_: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      } //未实名 无套餐 为新卡 提醒文本
    },
    {
      type: 3,
      label: "已停用",
      text: {},
      usageText: {
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值",
        bothMsg: "当前卡片流量与语音已用完或即将用完，如需使用，请尽快充值",
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //未实名 有套餐 用量超过90% 提醒文本
      isNewCardText: {
        msg_: "当前卡片为新卡，需要充值以后才可以正常使用",
        router_: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      } //未实名 无套餐 为新卡 提醒文本
    },
    {
      type: 4,
      label: "违章停机",
      text: {
        msg: "当前卡片状态异常，请稍后刷新重试或联系客服",
        msg_: "状态不可操作",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 5,
      label: "已失效",
      text: {}
    },
    {
      type: 6,
      label: "可测试",
      text: {},
      usageText: {
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值",
        bothMsg: "当前卡片流量与语音已用完或即将用完，如需使用，请尽快充值",
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //未实名 有套餐 用量超过90% 提醒文本
      isNewCardText: {
        msg_: "当前卡片为新卡，需要充值以后才可以正常使用",
        router_: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      } //未实名 无套餐 为新卡 提醒文本
    },
    {
      type: 7,
      label: "运营商注销",
      text: {
        msg: "当前卡片状态异常，请稍后刷新重试或联系客服",
        msg_: "状态不可操作",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 8,
      label: "用户注销",
      text: {
        msg: "当前卡片状态异常，请稍后刷新重试或联系客服",
        msg_: "状态不可操作",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 9,
      label: "欠停",
      text: {
        msg: "当前卡片状态异常，请稍后刷新重试或联系客服",
        msg_: "状态不可操作，请联系客服",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 10,
      label: "挂失",
      text: {}
    },
    {
      type: 11,
      label: "库存",
      text: {},
      usageText: {
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值",
        bothMsg: "当前卡片流量与语音已用完或即将用完，如需使用，请尽快充值",
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //未实名 有套餐 用量超过90% 提醒文本
      isNewCardText: {
        msg_: "当前卡片为新卡，需要充值以后才可以正常使用",
        router_: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      } //未实名 无套餐 为新卡 提醒文本
    },
    {
      type: 12,
      label: "故障卡",
      text: {
        msg: "当前卡片状态异常，请稍后刷新重试或联系客服",
        msg_: "状态不可操作，请联系客服",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 13,
      label: "已断网",
      text: {
        msg: "卡片状态异常，建议您诊断下卡片",
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值", //目前没有相关卡资源  已确认过
        router: "intelligent-diagnosis",
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //常规提醒文本
      isRealNameText: {
        msg_: "卡片已经断网、请联系客服",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //是DCP 且 已经实名 提醒文本
      usageText: {
        totalFlowMsg: "当前卡片流量已用完或即将用完，如需使用，请尽快充值",
        totalVoiceMsg: "当前卡片语音已用完或即将用完，如需使用，请尽快充值", //目前没有相关卡资源  已确认过
        rechargeRouter: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      }, //是DCP 且 未实名 有套餐 用量超过90% 提醒文本
      isNewCardText: {
        msg_: "当前卡片为新卡，需要充值以后才可以正常使用",
        router_: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "确定"
      } //是DCP 且 未实名 无套餐 为新卡 提醒文本
    },
    {
      type: 14,
      label: "未实名停机",
      text: {
        msg: "当前卡片未实名，已被运营商停机，请立即前往实名",
        router: "personal-authenTication",
        cancelButtonText: "我知道了",
        confirmButtonText: "立即前往"
      }
    },
    {
      type: 15,
      label: "预注销",
      text: {
        msg: "卡片即将被销户，请及时充值",
        router: "personal-packageRecharge",
        cancelButtonText: "取消",
        confirmButtonText: "去充值"
      }
    },
    {
      type: 16,
      label: "超量停机",
      text: {
        msg: "卡片已经停机，请联系客服经理处理",
        msg_: "状态不可操作，请联系客服经理处理",
        toast: "toast",
        router: "fb-list",
        router_: "fb-list",
        cancelButtonText: "取消",
        confirmButtonText: "联系客服"
      }
    },
    {
      type: 17,
      label: "停机保号",
      text: {
        msg_: "状态不可操作",
        toast: "toast"
      }
    },
    {
      type: 18,
      label: "已锁定",
      text: {}
    },
    {
      type: 88,
      label: "维护中",
      text: {}
    },
    {
      type: 55555,
      label: "注销",
      text: {
        msg_: "状态不可操作",
        toast: "toast"
      }
    }
  ]
};

export default DIC;
