<template>
  <div class="content_box_">
    <div class="appbar">
      <div class="exhibition">
        <div class="icon">
          <img src="../../assets/image/popup/icon_recharge_card.png" alt="" />
        </div>
        <div class="desc">{{isReceivePackage ? '领取套餐提醒' : '套餐生效提醒'}}</div>
      </div>
    </div>
    <div class="content">
      <slot>
        <div class="msg">{{ title }}</div>
      </slot>
      <slot>
        <div class="choice">
          <div class="cancel" @click="close">取消</div>
          <div class="submit" @click="submit">{{isReceivePackage ? '立即领取' : '立即生效'}}</div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "EffectContainer",
  props: {
    title: {
      type: String,
      default: ""
    },
    isReceivePackage: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $router: router } = ctx;
    const methods = {
      close() {
        context.emit("show", false);
      },
      submit() {
        context.emit("effectPakage");
      }
    };
    return { ...methods };
  }
});
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixin.scss";

.content_box_ {
  display: flex;
  flex-direction: column;
  width: 615px;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
    .exhibition {
      background: #2196f3;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        padding-left: 24px;
        img {
          width: 498px;
          height: 95px;
        }
      }

      .desc {
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .content {
    flex: 1;
    overflow: auto;
    .msg {
      height: 140px;
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 30px;
      text-align: center;
      line-height: 150px;
    }
    .choice {
      height: 88px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 88px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }
      .submit {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #2196f3;
      }
    }
  }
}
</style>
