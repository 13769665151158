<template>
  <div class="content_box_">
    <div class="appbar">
      <div class="exhibition">
        <div class="icon">
          <img src="../../assets/image/popup/icon_recharge_card.png" alt="" />
        </div>
        <div class="desc">充值提醒</div>
      </div>
    </div>
    <div class="content">
      <slot>
        <div class="msgBox">
          <p class="title">{{ title }}</p>
        </div>
      </slot>
      <!-- <div @click="handleToDistribution" v-if="xyck_agentInfo.isShow" class="distribution" >去赚钱充套餐</div> -->
      <slot>
        <div class="choice">
          <div class="cancel" @click="close">取消</div>
          <div class="submit" @click="submit">确定</div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import {
  wxAuthUrl,
} from "_API_/zuJiApi";
export default defineComponent({
  name: "DialogContainer",
  props: {
    title: {
      type: String,
      default: ""
    },
    xyck_agentInfo:{
      type: Object,
      default: {
        isShow: false,
        platformCode: null,
        iccid: null,
        iotPhone:'',
      }
    },
  },
  setup(props, context) {
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $router: router } = ctx;
    const methods = {
      // 跳转创客分销
      // handleToDistribution(){
      //   location.href = `${wxAuthUrl}/wxAuth/login?type=11&iotPhone=${ctx.xyck_agentInfo.iotPhone}&platformCode=${ctx.xyck_agentInfo.platformCode}&iccid=${ctx.xyck_agentInfo.iccid}`

      // },
      close() {
        context.emit("show", false);
      },
      submit() {
        router.push({
          name: "personal-packageRecharge", //前往套餐充值
          query: { type: 0 }
        });
      }
    };
    return { ...methods };
  }
});
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixin.scss";

.content_box_ {
  display: flex;
  flex-direction: column;
  width: 615px;
  .appbar {
    @include border-1px(#eeeeee, bottom);
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
    .exhibition {
      background: #2196f3;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        padding-left: 24px;
        img {
          width: 498px;
          height: 95px;
        }
      }

      .desc {
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .content {
    flex: 1;
    overflow: auto;
    .msgBox {
      height: 140px;
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 15px;
      text-align: center;
      .title {
        margin-top: 48px;
      }
    }
    .choice {
      height: 88px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 88px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }
      .submit {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #2196f3;
      }
    }
  }
}
.distribution{
  width: 300px;
  margin: 10px auto;
  background-color: #2196f3;
  color: #ffffff;
  font-size: 32px;
  line-height: 60px;
  border-radius: 40px;
  text-align: center;
}
.distribution:active{
  opacity: 0.7;
}
</style>
