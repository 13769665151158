/**
 * 全局挂载工具类
 */
// 扫描modules目录下的所有js文件
const files = require.context("./modules", false, /\.js$/);

// 根据单个文件设置挂载点的名称
const components = files.keys().map(key => {
  const name = key.replace(/^.*\/|\..*$/g, "");
  // eslint-disable-next-line
  return files(key).hasOwnProperty("default")
    ? Object.assign(files(key).default, { key: name })
    : "";
});

export default {
  install(vm) {
    components.forEach(component => {
      if (component !== "") {
        Object.assign(vm.config.globalProperties, {
          [`$${component.key}`]: component
        });
      }
    });
  }
};
