<template>
  <van-popup
    v-model:show="show"
    round
    closeable
    teleport="body"
		class="modifyPopup"
  >
    <div class="adDialog">
      <img
        class="img"
        @click="toChangeCard"
        :src="require('_ASSETS_/image/personal/leaseJump.png')"
        alt=""
      />
    </div>
  </van-popup>
</template>

<script>

import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
export default defineComponent({
  name: "adLeaseDialog",
  setup() {
    const state = reactive({
      show: false,
      info:{}
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $router: router, $store: store } = ctx;
  
    const methods = {
      // 弹窗展示
      init() {
        state.show = true;
      },
      // 跳转至租赁平台
      toChangeCard() {
        location.href = `http://web.zuji.xiaoyizuji.com/#/adv-dxhk/4?cardNo=${state.info.iccid}`
      }
    };
    onMounted(()=>{
      state.info = store.state.user.userInfo;
    })
    return { ...toRefs(state), ...methods };
  }
});
</script>
<style lang="scss">
.modifyPopup{
	background-color:transparent !important;
}
</style>
