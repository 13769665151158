<template>
  <div>
    <router-view class="router" />
    <!-- <loading-container v-if="loadingShow"></loading-container> -->
    <van-overlay :show="loadingShow" z-index="9999" :custom-style='layStyle'>
      <div class="wrapper" @click.stop>
        <img class="loadimg" :src="require('_ASSETS_/image/common/loading.gif')" alt="" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
// 需要哪些模块按需引入
import {
  toRefs,
  reactive,
  getCurrentInstance,
  computed,
  watch
} from "vue";

export default {
  setup() {
    const state = reactive({
      loadingShow:false,
      layStyle:{
        backgroundColor:'rgba(226,226,226,0.39)'
      }
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $store: store } = ctx;
    //计算属性=>loadingShow
    const loadingStatus = computed(() => {
      return store.state.common.showLoading;
    })
    watch(loadingStatus, (val,oldVal) => {
      methods.changeStatus(val)
    })

    const methods = {
      //按钮提交
      changeStatus: val => state.loadingShow = val
    }
    return {
      ...methods,
      ...toRefs(state)
    };
  }
};
</script>
<style lang="less">
#app {
  height: 100vh;
  font-family: PingFangSC-Regular;
  background: #f1f2f3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .loadimg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    height: 100px;
  }
}
</style>
