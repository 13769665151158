<template>
  <van-popup
    v-model:show="show"
    round
    closeable
    :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)"
  >
    <div class="codePopup">
      <!-- 标题 -->
      <div class="title">安全验证</div>
      <div class="mobile_content">
        <slot>
          <!-- 手机号码输入框  验证码按钮 -->
          <div class="mobile_num">
            <span class="desc">手机号码:</span>
            <input
              class="mobile_input"
              type="text"
              style="border: none"
              placeholder="请输入手机号码"
              v-model="mobile"
              maxlength="11"
            />
            <button
              class="verification_code"
              :class="{
                verification_code:
                  mobile && mobile.length == 11 && timeStyle && !timeStyleZero,
                verification_code_:
                  (mobile &&
                    mobile.length == 11 &&
                    !timeStyle &&
                    !timeStyleZero) ||
                  (mobile && mobile.length == 11 && !timeStyle && timeStyleZero)
              }"
              :disabled="time != '获取验证码'"
              @click="getSms"
            >
              {{ time }}
            </button>
          </div>
        </slot>
      </div>
      <div class="mobile_code">
        <slot>
          <!-- 验证码 -->
          <div class="mobile_num">
            <span class="desc">验证码:</span>
            <input
              class="mobile_input"
              type="text"
              style="border: none"
              placeholder="请输入验证码"
              v-model="code"
              maxlength="6"
            />
          </div>
        </slot>
      </div>
      <button class="submit" :disabled="flag" @click="delCard">确定</button>
    </div>
  </van-popup>
  <div v-if="flag">
    <van-loading type="spinner" :color="tool.getThemeList(theme)" vertical />
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted
} from "vue";
import { setStore, removeStore } from "@/utils/store";
import {
  getMobileCode,
  cancelCard,
  getMobileIsRealName
} from "_API_/api.services";
import { isvalidatemobile } from "@/utils/validate";
export default defineComponent({
  name: "CodePopup",
  props: {},

  setup() {
    const state = reactive({
      card: "", //卡号
      virtualId: "", //充值卡号
      cardId: "", //卡ID
      iccids: [], //用户绑定的卡片列表
      mobile: "", //手机号码
      code: "", //验证码
      time: "获取验证码",
      openIdcard: "", //openIdcard
      timeId: "", //验证码定时器Id
      timeStyle: false, //验证码样式控制
      timeStyleZero: false, //验证码倒计时为0的样式控制
      type: 3,
      show: false, //注销卡片弹框展示
      flag: false, //注销确定按钮&&Loading
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的ctx
    const { proxy: ctx } = getCurrentInstance();
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    const methods = {
      //注销卡片弹框操作
      isShow() {
        state.type = 5;
        state.show = true;
      },
      //注销卡片后的跳转
      routerJump() {
        if (Boolean(state.iccids.length) && state.iccids.length > 1) {
          let index = null;
          state.iccids.map((item, i) => {
            if (item.iccid == state.iccid) {
              index = i;
            }
          });
          state.iccids.splice(index, 1);
          setStore({
            name: "userInfo",
            content: {
              iccid: state.iccids[0].iccid
            },
            type: "session"
          });
          router.push({
            name: "personal"
          });
          return;
        }
        //从sessionStorage删除loginAccessToken标识
        removeStore({
          name: "loginAccessToken",
          type: "session"
        });
        //从sessionStorage删除userInfo标识
        removeStore({
          name: "userInfo",
          type: "session"
        });
        //从sessionStorage删除userBind标识
        removeStore({
          name: "userBind",
          type: "session"
        });
        //跳转回查询界面
        router.push({
          name: "card-query"
        });
      },
      //注销卡片
      delCard() {
        tool.confirm(
          {
            title: "注销卡片",
            msg: `确定注销卡片：${state.virtualId || state.card}`
          },
          () => {
            if (state.code != "") {
              state.flag = true;
              cancelCard({
                mobile: state.mobile,
                card: state.card,
                code: state.code,
                reason: ""
              }).then(({ data: res }) => {
                if (res.code == 0) {
                  tool.toast(
                    {
                      msg: "卡片注销成功",
                      duration: 1500
                    },
                    () => {
                      state.flag = false;
                      //关闭注销卡片弹框
                      state.show = false;
                      ctx.routerJump();
                    }
                  );
                  return;
                }
                state.flag = false;
                tool.toast(
                  {
                    msg: res.msg,
                    duration: 1500
                  },
                  () => {}
                );
              });
            } else {
              tool.toast(
                {
                  msg: "请输入正确的手机号并获取验证码",
                  duration: 1500
                },
                () => {}
              );
            }
          }
        );
      },
      //获取验证码
      getSms() {
        //检测手机号
        if (!Boolean(state.mobile) || state.mobile.length < 11) {
          tool.alert({
            title: "提示",
            msg: "请输入正确的手机号"
          });
          return;
        }
        //验证手机号
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            msg: result_phone[1]
          });
          return;
        }
        //检测手机号码是否已经绑定过
        getMobileIsRealName({
          card: state.card,
          mobile: state.mobile
        }).then(({ data }) => {
          if (data.code == 0) {
            state.openIdcard = data.realName;
            return data;
          }
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        });
        //验证码接口
        getMobileCode({
          type: state.type,
          card: state.card,
          mobile: state.mobile,
          cardId: state.cardId
        }).then(({ data }) => {
          if (data.code == 0) {
            ctx.timeBack(60);
            tool.toast({
              msg: "发送成功",
              duration: 1500
            });
            return;
          }
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        });
      },
      //验证码回调
      timeBack(time) {
        if (time <= 0) {
          clearTimeout(state.timeId);
          return;
        }
        state.time = time--;
        state.timeStyle = true;
        state.time = time + "s";
        if (state.time === "0s") {
          state.timeStyleZero = true;
          state.timeStyle = false;
          state.time = "获取验证码";
        }
        state.timeId = setTimeout(() => {
          ctx.timeBack(time);
        }, 1000);
      }
    };

    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      //注销时展示手机号码
      state.mobile = info.mobile || "";
      state.cardId = info.cardId || ""; //获取卡Id
      state.card = info.virtualId || info.iccid || ""; //获取卡号
      state.virtualId = info.virtualId;
      state.iccids = info.iccids; //用户绑定的卡片列表
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
});
</script>
<style lang="scss" scoped>
@import "@/style/theme/index";
.codePopup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .title {
    text-align: center;
    margin-top: 32px;
    font: {
      size: 30px;
      weight: 400;
    }
    color: #333333;
  }
  .mobile_content {
    flex: 1;
    overflow: auto;
    .mobile_num {
      width: 600px;
      height: 88px;
      justify-content: space-around;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }

      .mobile_input {
        width: 214px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .verification_code {
        width: 170px;
        height: 50px;
        font: {
          size: 22px;
          weight: 400;
        }
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        background: #aaaaaa;
        border-radius: 10px;
        border: none;
      }
      .verification_code_ {
        width: 170px;
        height: 50px;
        font: {
          size: 22px;
          weight: 400;
        }
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        @include background_color("background_color1");
        border-radius: 10px;
        border: none;
      }
    }
  }
  .mobile_code {
    flex: 1;
    overflow: auto;
    .mobile_num {
      width: 560px;
      height: 88px;
      justify-content: space-between;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }

      .mobile_input {
        width: 214px;
        margin-right: 196px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
    }
  }
  .submit {
    width: 530px;
    height: 84px;
    line-height: 84px;
    color: #ffffff;
    @include background_color("background_color1");
    box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 20px;
    border: none;
    text-align: center;
    margin: 0 auto 40px;
  }
}
</style>
