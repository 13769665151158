<template>
  <div class="Circleprogress">
    <svg
      :width="width"
      :height="width"
      :viewbox="`0 0 ${width} ${width}`"
      class="svgWrap"
    >
      <circle
        :cx="width * 0.5"
        :cy="width * 0.5"
        :r="width * 0.4"
        :stroke-width="width * 0.05"
        stroke-linecap="round"
        :stroke="backColor"
        fill="none"
        stroke-opacity="0.2"
        :transform="`matrix(0,-1,1,0,0,${width})`"
        :stroke-dasharray="
          `${0.75 * (width * 0.4 * Math.PI * 2)} ${0.25 *
            (width * 0.4 * Math.PI * 2)}`
        "
      ></circle>
      <circle
        ref="progress"
        :cx="width * 0.5"
        :cy="width * 0.5"
        :r="width * 0.4"
        :stroke-width="width * 0.05"
        stroke-linecap="round"
        :stroke="showVoice ? lineColor_ : lineColor"
        fill="none"
        :transform="`matrix(0,-1,1,0,0,${width})`"
        :stroke-dasharray="dasharray"
      ></circle>
    </svg>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
export default defineComponent({
  name: "Circleprogress",
  props: {
    width: {
      type: Number,
      default: 100
    },
    backColor: {
      type: String,
      default: "yellow"
    },
    lineColor: {
      type: String,
      default: "pink"
    },
    lineColor_: {
      type: String,
      default: "pink"
    },
    showVoice: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const state = reactive({
      dasharray: `0 ${props.width * 0.4 * Math.PI * 2}`
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool } = ctx;
    const methods = {
      draw(percent = 1) {
        ctx.$nextTick(() => {
          let per = 0.75 * percent;
          let circle = ctx.$refs["progress"];
          let perimeter = circle.getTotalLength(); //圆环的周长
          state.dasharray = perimeter * per + " " + perimeter * (1 - per);
        });
      }
    };
    onMounted(() => {});
    return { ...toRefs(state), ...methods };
  }
});
</script>
<style lang="scss" scoped>
.Circleprogress {
  overflow: hidden;
  .svgWrap {
    transform: rotateZ(-135deg);
    circle {
      /* 圆环的过渡动画 */
      -webkit-transition: stroke-dasharray 800ms;
      transition: stroke-dasharray 800ms;
    }
  }
}
</style>
