<template>
  <div class="banner" :class="fill">
    <!-- <van-swipe :autoplay="5000">
      <van-swipe-item v-for="(item, index) in adList" :key="index" class="swiper-item">
        <img class="icon"  :src="`/img${item.imgPath}`" @click="clickBanner(item)" alt />
      </van-swipe-item>
    </van-swipe> -->
    <div>
      <van-swipe class="my-swipe" :autoplay="bannerInfo.intervalStatus ? bannerInfo.timeInterval * 1000 : 0"
        indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="openUrl(item.url, item.needParams)">
          <img :src="item.imgUrl" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- <div class="message" v-if="xyck_agentInfo.isShow" @click="navDistribution">
      <div class="message-box">
        <div class="message-box-text">
          尊敬的客户您好，感谢您一直以来对我们的支持，现在我们开通了分销商城啦，<span>快来分销赚外快吧！</span>
        </div>
        <img class="message-box-img" src="../../assets/image/record/arrow.png" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
// 目轮播广告只是针对互电引流做的 具有针对性，后期后台对接配置广告时需修改

import { defineComponent, getCurrentInstance, reactive, toRefs, onMounted } from "vue"
import { getStore } from "@/utils/store";
import { advertLog, zyAdBannerList, zyAdBannerInfo } from "_API_/api.services";
import { isDevice } from "@/utils/utils";
import {
  wxAuthUrl,
} from "_API_/zuJiApi";
export default defineComponent({
  name: "AdBanner",
  props: {
    _platformCode: {
      type: String,
      default: ''
    }
  },
  setup() {
    const state = reactive({
      theme: "", //主题名称
      xyck_agentInfo: {
        isShow: false,
        platformCode: null,
        iccid: null,
        iotPhone: '',
      },//是否展示创客分销入口
      url: "",
      fill: "fill-cover",
      bannerList: [], // 广告数组
      bannerInfo: {}, // 广告参数
      YYbannerList:[{
        imgUrl:'http://zy-iot.oss-cn-shenzhen.aliyuncs.com/www.jhy.com/20220705/a30abbd4a39a4b0c8d67e283196fad77.jpg'
      }],//语音平台图片写死，
      adList: [{
        imgPath: '/iot_banner/banner1.png',
        url: null
      }, {
        imgPath: '/iot_banner/banner1.png',
        url: null
      }],
      device: '',
      hdBannerLoad: true
    });
    // if(window.SITE_CONFIG['openHdAdv']){
    //    state.adList= window.SITE_CONFIG['openHdAdv']
    //  }
    // 预留查询轮播
    // let mainBanner = getStore({name:'mainBanner'})
    // if(mainBanner && mainBanner.length>=1){
    //   state.adList = mainBanner
    // }
    //检测用户设备
    // state.device = isDevice();

    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;

    const methods = {
      //跳转创客分销
      // navDistribution() {
      //   location.href = `${wxAuthUrl}/wxAuth/login?type=11&iotPhone=${state.xyck_agentInfo.iotPhone}&platformCode=${state.xyck_agentInfo.platformCode}&iccid=${state.xyck_agentInfo.iccid}`

      // },
      // clickBanner(item){
      //   if(item.url){
      //     location.href = item.url
      //   }
      // },
      // advertLogFn(type,cb=()=>{}){
      //   advertLog({
      //     param: '',
      //     type,
      //     browserType: state.device,
      //   }).then(()=>{
      //     cb()
      //   })
      // },
      // 广告跳转
      openUrl(url, needParams) {
        // 若有跳转地址 且 需要携带参数 则拼接字段
        if (Boolean(url) && Number(needParams) == 1) {
          location.href = url + `&channelSource=${ctx._platformCode}`
        }
        // 若有跳转地址 且 不需要携带参数 则直接跳转
        if (Boolean(url) && Number(needParams) == 0) {
          location.href = url
        }
      },
      async getBannerList() {
        let { data } = await zyAdBannerList(3)
        if (data.code == 0) {
          const stableSorting = (s1, s2) => {
            if (s1.sort < s2.sort) return -1;
            return 1;
          };
          state.bannerList = data.data.sort(stableSorting);
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      async getBannerInfo() {
        let { data } = await zyAdBannerInfo(3)
        if (data.code == 0) {
          state.bannerInfo = data.data
        } else {
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
    };
    onMounted(() => {
      // methods.advertLogFn(9)
      //获取主题名称
      state.theme = store.state.common.theme;
      // 查询广告
      methods.getBannerList()
      // 查询广告参数
      methods.getBannerInfo()
      // if (localStorage.getItem('xyck_agentInfo')) {
      //   state.xyck_agentInfo = JSON.parse(localStorage.getItem('xyck_agentInfo'))
      // }

    })
    return { ...toRefs(state), ...methods };
  },
});
</script>
<style lang="scss">
@import "~@/assets/style/mixin.scss";

.banner {
  padding: 30px 30px 0;
  position: relative;

  &.fill-cover {
    padding: 0;

    .van-swipe {
      border-radius: 0;
    }
  }

  .van-swipe {
    border-radius: 10px;

    .swiper-item {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

// #animation{
// -webkit-animation:shake 1s .2s ease both;
// -moz-animation:shake 1s .2s ease both;}
@-webkit-keyframes shake {

  0%,
  100% {
    -webkit-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {

  0%,
  100% {
    -moz-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -moz-transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -moz-transform: translateX(10px);
  }
}

.message {
  width: 100%;
  height: 128px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(24px);
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-animation: shake 1s .2s ease both;
  -moz-animation: shake 1s .2s ease both;

  &-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    font-size: 28px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.9);

    &-text {
      margin-right: 24px;

      span {
        color: #F18B8D;
      }
    }

    &-img {
      width: 52px;
      height: 32px;
      transform: rotate(-90deg);

    }
  }
}
</style>
