import { getStore, setStore } from "@/utils/store";
let common = {
  state: {
    showLoading: false,
    theme: getStore({ name: "themeName" }) || "",
    platformCode: getStore({ name: "platformCode" }) || "",
  },
  actions: {
    //请求设置
    SetRequest({ commit }, type) {
      commit("SET_REQ", type);
    },
    // 平台code 码
    SetPlatformCode({commit}, data) {
      commit("SET_CODE", data)
    }
  },
  mutations: {
    SET_REQ: (state, req) => {
      state.showLoading = req;
    },
    SET_CODE: (state, code) => {
      state.platformCode = code
      setStore({
        name: "platformCode",
        content: state.platformCode,
        type: "session"
      });
    }
  }
};

export default common;
