import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { setStore} from "@/utils/store";
import FastClick from "fastclick";
import { vantPlugins } from "./plugins/vant";
import CustomComponent from "./components/index";
import "./style/reset.less";
import utils from "./utils/index";
import { mainBanner } from "_API_/api.services";
// 创建App挂载点
const app = createApp(App);
// 处理点击事件延迟300ms问题
// FastClick.attach(document.body);

// 查询页轮播 暂时不用
// mainBanner().then(({data})=>{
//   if(data.code == 0 && data.list){
//     setStore({
//       name:"mainBanner",
//       content:list
//     })
//   }
//   mount()
// }).catch(()=>{
//   mount()
// })


function mount(){
  app
  .use(store)
  .use(router)
  .use(utils)
  .use(CustomComponent)
  .use(vantPlugins)
  .mount("#app");
}
mount()